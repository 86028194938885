import React from "react";
import { AppRouter } from "./Routers/AppRouter";
import { inject } from "@vercel/analytics";
import { SpeedInsights } from "@vercel/speed-insights/react";
import 'text-encoding';

inject();

function App() {
  return (
    <div>
      <AppRouter />
      <SpeedInsights />
    </div>
  );
}

export default App;
