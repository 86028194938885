import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image44 from "../../../Images/image44.png";
import image45 from "../../../Images/image45.png";
import image46 from "../../../Images/image46.png";

import HeaderQuestionario from "../../../Components/headerQuestionarios";

const images = [image44, image45, image46];
const cardTitles = [
  "ANSIEDADE - BECK",
  "DEPRESSÃO - BECK",
  "SOFRIMENTO MENTAL",
];

const Form10000 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Inventario-Ansiedade-Beck`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Inventario-Depressao-Beck`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Questionario-Sofrimento-Mental`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderQuestionario />
      <MainContent>
        <WelcomeText>Sofrimento Mental</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form10000;
