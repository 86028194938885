import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image32 from "../../../Images/image32.png";
import image33 from "../../../Images/image33.png";
import image34 from "../../../Images/image34.png";
import image35 from "../../../Images/image35.png";
import HeaderQuestionarios from "../../../Components/headerQuestionarios";

const images = [
  image32,
  image33,
  image34,
  image35,
  image32,
  image32,
  image32,
  image32,
  image32,
  image32,

  image32,
];
const cardTitles = [
  "MÉDICO",
  "NUTRICIONISTA",
  "PSICÓLOGO",
  "PROFISSIONAL DE EDUCAÇÃO FÍSICA",
  "FISIOTERAPEUTA",
  "CIRURGIÃO DENTISTA",
  "MÉDICO PSQUIATRA",
  "MÉDICO CARDIOLOGISTA",
  "MÉDICO ORTOPEDISTA",
  "MÉDICO OFTAMOLOGISTA",
  "MÉDICO CLÍNICO GERAL",
];

const EscolherTeleConsulta = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 4) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 5) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 6) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 7) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 8) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 9) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 10) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 11) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 12) {
      navigate(`/plataforma/video-chamada`);
    } else if (cardNumber === 13) {
      navigate(`/plataforma/video-chamada`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <>
      <HeaderQuestionarios />
      <MainContent>
        <WelcomeText>Tele consulta</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </>
  );
};

export default EscolherTeleConsulta;
