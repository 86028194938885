import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import HeaderGeral from "../../../Components/headerGeral";
import { useNavigate } from "react-router-dom";
import {
  Container,
  FormInput,
  FormInput1,
  FormContainer,
  FormGroup,
  FormHeader,
  FormLabel,
  FormRadioGroup,
  FormRadio,
  Button,
  FormLabelOptions,
  DivInputs,
  ButtonSend,
} from "./styles";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form3 = () => {
  const [id, setId] = useState("");
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [cidade, setCidade] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleCidadeChange = (e) => {
    setCidade(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      id,
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      cidade,
    };

    const { error } = await supabase.from("caracHabVida").insert([formData]);

    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>CARACTERIZAÇÃO DOS HÁBITOS DE VIDA</h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Nº de CPF :</FormLabel>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="20.87vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroup>
          <DivInputs>
            <FormGroup>
              <FormLabel>Fumante ou ex-fumante</FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q1"
                    value="Sim (fumante)"
                    onChange={(e) => setQ1(e.target.value)}
                  />
                  Sim (fumante)
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q1"
                    value="Sim (ex-fumante)"
                    onChange={(e) => setQ1(e.target.value)}
                  />
                  Sim (ex-fumante)
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q1"
                    value="Não Fuma"
                    onChange={(e) => setQ1(e.target.value)}
                  />
                  Não Fuma
                </FormLabelOptions>
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Consumo de álcool</FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q2"
                    value="De uma a duas vezes por semana"
                    onChange={(e) => setQ2(e.target.value)}
                  />
                  De uma a duas vezes por semana
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q2"
                    value="De três a quatro vezes por semana"
                    onChange={(e) => setQ2(e.target.value)}
                  />
                  De três a quatro vezes por semana
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q2"
                    value="Mais de 4 vezes por semana"
                    onChange={(e) => setQ2(e.target.value)}
                  />
                  Mais de 4 vezes por semana
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q2"
                    value="Não consumo bebida alcoólica"
                    onChange={(e) => setQ2(e.target.value)}
                  />
                  Não consumo bebida alcoólica
                </FormLabelOptions>
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Alimentação saudável</FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q3"
                    value="Sim"
                    onChange={(e) => setQ3(e.target.value)}
                  />
                  Sim
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q3"
                    value="Não"
                    onChange={(e) => setQ3(e.target.value)}
                  />
                  Não
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q3"
                    value="Não sei opinar sobre isso"
                    onChange={(e) => setQ3(e.target.value)}
                  />
                  Não sei opinar sobre isso
                </FormLabelOptions>
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Dificuldades para dormir</FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q4"
                    value="Sim"
                    onChange={(e) => setQ4(e.target.value)}
                  />
                  Sim
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q4"
                    value="Não"
                    onChange={(e) => setQ4(e.target.value)}
                  />
                  Não
                </FormLabelOptions>
              </FormRadioGroup>
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                Meio de transporte utilizado para ir ao trabalho
              </FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q5"
                    value="Carro"
                    onChange={(e) => setQ5(e.target.value)}
                  />
                  Carro
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q5"
                    value="Moto"
                    onChange={(e) => setQ5(e.target.value)}
                  />
                  Moto
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q5"
                    value="Ônibus"
                    onChange={(e) => setQ5(e.target.value)}
                  />
                  Ônibus
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q5"
                    value="Outros"
                    onChange={(e) => setQ5(e.target.value)}
                  />
                  Outros
                </FormLabelOptions>
              </FormRadioGroup>
            </FormGroup>
            <FormGroup>
              <FormLabel>Possui moradia própria</FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q6"
                    value="Sim"
                    onChange={(e) => setQ6(e.target.value)}
                  />
                  Sim
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q6"
                    value="Não"
                    onChange={(e) => setQ6(e.target.value)}
                  />
                  Não
                </FormLabelOptions>
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Reside em Teresina?</FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q8"
                    value="Sim"
                    onChange={(e) => setQ8(e.target.value)}
                  />
                  Sim
                </FormLabelOptions>

                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q8"
                    value="Não"
                    onChange={(e) => setQ8(e.target.value)}
                  />
                  Não
                </FormLabelOptions>
                <p>Se não, informe a cidade</p>

                <FormInput1
                  type="text"
                  name="cidade"
                  value={cidade}
                  onChange={handleCidadeChange}
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Sua residência fica em qual zona da cidade</FormLabel>
              <FormRadioGroup>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value="Centro"
                    onChange={(e) => setQ7(e.target.value)}
                  />
                  Centro
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value="Leste"
                    onChange={(e) => setQ7(e.target.value)}
                  />
                  Leste
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value="Sudeste"
                    onChange={(e) => setQ7(e.target.value)}
                  />
                  Sudeste
                </FormLabelOptions>

                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value="Norte"
                    onChange={(e) => setQ7(e.target.value)}
                  />
                  Norte
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value="Sul"
                    onChange={(e) => setQ7(e.target.value)}
                  />
                  Sul
                </FormLabelOptions>
                <FormLabelOptions>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value="Outra"
                    onChange={(e) => setQ7(e.target.value)}
                  />
                  Outra
                </FormLabelOptions>
              </FormRadioGroup>
            </FormGroup>
          </DivInputs>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>

            <ButtonSend type="submit">Enviar</ButtonSend>
          </div>
        </form>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form3;
