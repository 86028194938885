import React, { useState } from "react";
import {
  Container,
  RightImage,
  FormContainer,
  FormGroup,
  FormHeader,
  FormLabel,
  FormRadioGroup,
  FormRadio,
  FilterButton,
  ResultContainer,
  FormInput,
  ResultStatus,
  Button,
  FormGroupIntro,
  FormLabelIntro,
  DivInfos,
  Infos,
} from "./styles";
import supabase from "../../../Supabase/supabase";
import HeaderGeral from "../../../Components/headerGeral";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form14 = () => {
  const [showResult, setShowResult] = useState(false);
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [q9, setQ9] = useState("");
  const [q10, setQ10] = useState("");
  const [q11, setQ11] = useState("");
  const [q12, setQ12] = useState("");
  const [q13, setQ13] = useState("");
  const [q14, setQ14] = useState("");
  const [q15, setQ15] = useState("");
  const [q16, setQ16] = useState("");
  const [resultado1, setResultado1] = useState("");
  const [resultado2, setResultado2] = useState("");
  const [resultado3, setResultado3] = useState("");
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const todasRespostasPreenchidas = () => {
    return (
      q1.trim() !== "" &&
      q2.trim() !== "" &&
      q3.trim() !== "" &&
      q4.trim() !== "" &&
      q5.trim() !== "" &&
      q6.trim() !== "" &&
      q7.trim() !== "" &&
      q8.trim() !== "" &&
      q9.trim() !== "" &&
      q10.trim() !== "" &&
      q11.trim() !== "" &&
      q12.trim() !== "" &&
      q13.trim() !== "" &&
      q14.trim() !== "" &&
      q15.trim() !== "" &&
      q16.trim() !== ""
    );
  };

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, ""); // Mantém apenas números
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  function categorizar(valor) {
    if (valor >= 0 && valor <= 15) {
      return "baixo";
    } else if (valor >= 16 && valor <= 25) {
      return "médio";
    } else if (valor >= 26 && valor <= 94) {
      return "alto";
    } else {
      return "valor inválido";
    }
  }

  function somarECategorizar() {
    let soma1 =
      parseInt(q1) +
      parseInt(q2) +
      parseInt(q3) +
      parseInt(q4) +
      parseInt(q5) +
      parseInt(q6);
    let soma2 = parseInt(q7) + parseInt(q8) + parseInt(q9) + parseInt(q10);
    let soma3 =
      parseInt(q11) +
      parseInt(q12) +
      parseInt(q13) +
      parseInt(q14) +
      parseInt(q15) +
      parseInt(q16);

    setResultado1(categorizar(soma1));
    setResultado2(categorizar(soma2));
    setResultado3(categorizar(soma3));

    setShowResult(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      id,
      resultado1,
      resultado2,
      resultado3,
    };

    const { data, error } = await supabase
      .from("avaliFatorBurn")
      .insert([formData]);

    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Estresse-laboral");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>VARIAVEIS POR FATOR DE BURNOUT</h2>
        </FormHeader>

        <FormGroupIntro>
          <FormLabelIntro>
            Por favor, leia atentamente cada um dos itens a seguir e responda se
            já experimentou o que é relatado, em relação a seu trabalho. Não
            existem respostas certas ou erradas e não haverá nenhum dado que
            possa lhe identificar. Neste primeiro momento pedimos que você leia
            com atenção as questões abaixo e responda conforme a legenda, com
            qual frequência estes eventos ocorrem. Pontue de 0-6 os itens a
            seguir conforme seus sentimentos e sintomas.
          </FormLabelIntro>
        </FormGroupIntro>

        <form onSubmit={handleSubmit}>
          <FormGroupIntro>
            <FormLabelIntro>Nº de CPF :</FormLabelIntro>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="21.87vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroupIntro>
          <DivInfos>
            <Infos>Nunca</Infos>
            <Infos>Uma vez ao ano ou menos</Infos>
            <Infos>Uma vez ao mês ou menos</Infos>
            <Infos>Algumas vezes ao mês</Infos>
            <Infos>Uma vez por semana</Infos>
            <Infos>Algumas vezes por semana</Infos>
            <Infos>Todos os dias</Infos>
          </DivInfos>

          <FormGroup>
            <FormLabel>
              Sinto-me emocionalmente esgotado com o meu trabalho
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q1"
                    value={value.toString()}
                    onChange={(e) => setQ1(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me esgotado no final de um dia de trabalho
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q2"
                    value={value.toString()}
                    onChange={(e) => setQ2(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me cansado quando me levanto pela manhã e preciso encarar
              outro dia de trabalho
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q3"
                    value={value.toString()}
                    onChange={(e) => setQ3(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Trabalhar o dia todo é realmente motivo de tensão para mim
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q4"
                    value={value.toString()}
                    onChange={(e) => setQ4(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Sinto-me acabado por causa do meu trabalho</FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q5"
                    value={value.toString()}
                    onChange={(e) => setQ5(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Só desejo fazer meu trabalho e não ser incomodado
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q6"
                    value={value.toString()}
                    onChange={(e) => setQ6(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sou menos interessado no meu trabalho desde que assumi essa função
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value={value.toString()}
                    onChange={(e) => setQ7(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Sou menos entusiasmado com o meu trabalho</FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q8"
                    value={value.toString()}
                    onChange={(e) => setQ8(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sou mais descrente sobre a contribuição de meu trabalho para algo
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q9"
                    value={value.toString()}
                    onChange={(e) => setQ9(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Duvido da importância do meu trabalho</FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q10"
                    value={value.toString()}
                    onChange={(e) => setQ10(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me entusiasmado quando realizo algo no meu trabalho
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q11"
                    value={value.toString()}
                    onChange={(e) => setQ11(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Realizo muitas coisas valiosas no meu trabalho
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q12"
                    value={value.toString()}
                    onChange={(e) => setQ12(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Posso efetivamente solucionar os problemas que surgem no meu
              trabalho.
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q13"
                    value={value.toString()}
                    onChange={(e) => setQ13(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto que estou dando uma contribuição efetiva para essa
              organização.
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q14"
                    value={value.toString()}
                    onChange={(e) => setQ14(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Na minha opinião, sou bom no que faço</FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q15"
                    value={value.toString()}
                    onChange={(e) => setQ15(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              No meu trabalho, me sinto confiante de que sou eficiente e capaz
              de fazer com que as coisas aconteçam.
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q16"
                    value={value.toString()}
                    onChange={(e) => setQ16(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
              marginBottom: 30,
              marginTop: 30,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>
            <FilterButton
              onClick={somarECategorizar}
              disabled={!todasRespostasPreenchidas()}
            >
              Calcular Média Geral
            </FilterButton>
          </div>

          {showResult && (
            <ResultContainer>
              <div>
                <ResultStatus>
                  <p>Exaustão Emocional: {resultado1}</p>
                  <p>Despersonalização: {resultado2}</p>
                  <p>Realização Profissional: {resultado3}</p>
                </ResultStatus>
              </div>
            </ResultContainer>
          )}
        </form>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form14;
