import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  Table,
  TableHeader,
  TableCell,
  FormInput,
  FormRadio,
  Button1,
  ResultContainer,
  Button,
  DivInputs,
  FormHeader,
} from "../../../Components/all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form40 = () => {
  const [sexoExames, setSexoExames] = useState("");
  const [idadeExames, setIdadeExames] = useState("");
  const [colesterolHDL, setColesterolHDL] = useState("");
  const [pressaoArterial, setPressaoArterial] = useState("");
  const [glicemia, setGlicemia] = useState("");
  const [circunferenciaCintura, setCircunferenciaCintura] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [hasAbdominalObesity, setHasAbdominalObesity] = useState(false);
  const [hasLowHDL, setHasLowHDL] = useState(false);
  const [hasHighBloodPressure, setHasHighBloodPressure] = useState(false);
  const [hasHighGlucose, setHasHighGlucose] = useState(false);
  const [hasInsulinResistance, setHasInsulinResistance] = useState(false);
  const [valorPCR, setValorPCR] = useState("");
  const [valorAST, setValorAST] = useState("");
  const [valorALT, setValorALT] = useState("");
  const [valorCK, setValorCK] = useState("");
  const [valorGlicemia, setValorGlicemia] = useState("");
  const [valorTriglicerídeos, setValorTriglicerídeos] = useState("");
  const [valorCreatinina, setValorCreatinina] = useState("");
  const [valorUreia, setValorUreia] = useState("");
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleGenerateResult = () => {
    const glicemiaDiagnostico = obterDiagnosticoGlicemia(
      parseFloat(valorGlicemia)
    );
    const cklDiagnostico = obterDiagnosticoCK(parseFloat(valorCK));
    const creatininaDiagnostico = obterDiagnosticoCreatinina(
      parseFloat(valorCreatinina)
    );
    const ureiaDiagnostico = obterDiagnosticoUreia(parseFloat(valorUreia));

    const abdominalObesity =
      (sexoExames === "Masculino" && Number(circunferenciaCintura) > 102) ||
      (sexoExames === "Feminino" && Number(circunferenciaCintura) > 88);

    const lowHDL =
      (sexoExames === "Masculino" && Number(colesterolHDL) < 40) ||
      (sexoExames === "Feminino" && Number(colesterolHDL) < 50);

    const pressaoArterialParts = pressaoArterial.split("/");
    const highBloodPressure =
      pressaoArterial &&
      pressaoArterial.includes("/") &&
      (Number(pressaoArterialParts[0]) >= 135 ||
        Number(pressaoArterialParts[1]) >= 85);

    const highGlucose = Number(glicemia) >= 110;

    const insulinResistance =
      (abdominalObesity ? 1 : 0) +
        (lowHDL ? 1 : 0) +
        (highBloodPressure ? 1 : 0) +
        (highGlucose ? 1 : 0) >=
      3;

    setShowResult(true);
    setHasAbdominalObesity(abdominalObesity);
    setHasLowHDL(lowHDL);
    setHasHighBloodPressure(highBloodPressure);
    setHasHighGlucose(highGlucose);
    setHasInsulinResistance(insulinResistance);
  };

  const obterDiagnosticoGlicemia = (valorGlicemia) => {
    if (valorGlicemia < 100) {
      return "Glicemia de jejum normal.";
    } else if (valorGlicemia >= 100 && valorGlicemia < 126) {
      return "Glicemia de jejum alterada. Risco de resistência insulínica.";
    } else if (valorGlicemia >= 126) {
      return "Diabetes. Risco elevado de resistência insulínica.";
    } else if (valorGlicemia <= 70) {
      return "Glicemia de jejum baixa ou hipoglicemia.";
    }
    return "";
  };

  const obterDiagnosticoTriglicerídeos = (valorTriglicerídeos) => {
    if (valorTriglicerídeos <= 150) {
      return "Desejável";
    } else if (valorTriglicerídeos >= 151 && valorTriglicerídeos <= 199) {
      return "Limítrofe";
    } else if (valorTriglicerídeos >= 200 && valorTriglicerídeos <= 499) {
      return "Alto";
    } else if (valorTriglicerídeos >= 500) {
      return "Muito alto";
    }
    return "";
  };

  const obterDiagnosticoCK = (valorCK) => {
    if (sexoExames === "Masculino") {
      if (valorCK <= 174) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorCK <= 140) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoCreatinina = (valorCreatinina) => {
    if (sexoExames === "Masculino") {
      if (valorCreatinina >= 0.7 && valorCreatinina <= 1.3) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorCreatinina >= 0.5 && valorCreatinina <= 1.0) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoUreia = (valorUreia) => {
    if (valorUreia >= 10 && valorUreia <= 45) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoAST = (valorAST) => {
    if (valorAST >= 10 && valorAST <= 46) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoALT = (valorALT) => {
    if (valorALT >= 7 && valorALT <= 46) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      diagnósticoGlicemia: obterDiagnosticoGlicemia(parseFloat(valorGlicemia)),
      diagnósticoCK: obterDiagnosticoCK(parseFloat(valorCK)),
      diagnósticoCreatinina: obterDiagnosticoCreatinina(
        parseFloat(valorCreatinina)
      ),
      diagnósticoUreia: obterDiagnosticoUreia(parseFloat(valorUreia)),
      diagnósticoALT: obterDiagnosticoALT(parseFloat(valorALT)),
      diagnósticoAST: obterDiagnosticoAST(parseFloat(valorAST)),
      diagnósticoTriglicerídeos: obterDiagnosticoTriglicerídeos(
        parseFloat(valorTriglicerídeos)
      ),
    };

    try {
      const { data: inserted, error } = await supabase
        .from("examesBioParc")
        .insert([data]);
      if (error) {
        throw error;
      }
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    } catch (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    }
  };

  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/plataforma/Exames-laboratoriais");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2> EXAMES BIOQUÍMICOS PARCIAL</h2>
        </FormHeader>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Nº de CPF :</FormLabel>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="22.87vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Sexo:</FormLabel>
            <FormRadioGroup>
              {["Masculino", "Feminino"].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="sexoExames"
                    value={value}
                    checked={sexoExames === value}
                    onChange={(e) => setSexoExames(e.target.value)}
                  />
                  <span>{value}</span>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Idade:</FormLabel>
            <FormRadioGroup>
              {[
                "18 a 29 anos",
                "30 a 40 anos",
                "41 a 51 anos",
                "52 a 62 anos",
                "Acima de 62 anos",
              ].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="idadeExames"
                    value={value}
                    checked={idadeExames === value}
                    onChange={(e) => setIdadeExames(e.target.value)}
                  />
                  <span>{value}</span>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>
          <DivInputs>
            <FormGroup>
              <FormLabel>Proteína C reativa (PCR):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da PCR"
                value={valorPCR}
                onChange={(e) => setValorPCR(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Aspartato Aminotransferase (AST):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da AST"
                value={valorAST}
                onChange={(e) => setValorAST(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              {" "}
              <FormLabel>Alanina Aminotransferase (ALT):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da ALT"
                value={valorALT}
                onChange={(e) => setValorALT(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Creatinoquinase (CK):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor Creatinoquinase (CK)"
                value={valorCK}
                onChange={(e) => setValorCK(e.target.value)}
                customWidth="11.87vw"
              />
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>Creatinina:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da creatinina"
                value={valorCreatinina}
                onChange={(e) => setValorCreatinina(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Ureia:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da ureia"
                value={valorUreia}
                onChange={(e) => setValorUreia(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Glicemia:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da glicemia"
                value={valorGlicemia}
                onChange={(e) => setValorGlicemia(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              {" "}
              <FormLabel>Triglicerídeos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor dos Triglicerídeos"
                value={valorTriglicerídeos}
                onChange={(e) => setValorTriglicerídeos(e.target.value)}
              />
            </FormGroup>
          </DivInputs>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>
            <Button1 onClick={handleGenerateResult}>Gerar Resultado</Button1>
          </div>
        </form>

        {showResult && (
          <ResultContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Exame</TableHeader>
                  <TableHeader>Resultado</TableHeader>
                  <TableHeader>Diagnóstico</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>AST (TGO)</TableCell>
                  <TableCell>{valorAST}</TableCell>
                  <TableCell>
                    {obterDiagnosticoAST(parseFloat(valorAST))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>ALT (TGP)</TableCell>
                  <TableCell>{valorALT}</TableCell>
                  <TableCell>
                    {obterDiagnosticoALT(parseFloat(valorALT))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Creatinoquinase (CK)</TableCell>
                  <TableCell>{valorCK}</TableCell>
                  <TableCell>
                    {obterDiagnosticoCK(parseFloat(valorCK))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Creatinina</TableCell>
                  <TableCell>{valorCreatinina}</TableCell>
                  <TableCell>
                    {obterDiagnosticoCreatinina(parseFloat(valorCreatinina))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Ureia</TableCell>
                  <TableCell>{valorUreia}</TableCell>
                  <TableCell>
                    {obterDiagnosticoUreia(parseFloat(valorUreia))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Glicemia</TableCell>
                  <TableCell>{valorGlicemia}</TableCell>
                  <TableCell>
                    {obterDiagnosticoGlicemia(parseFloat(valorGlicemia))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Triglicerídeos</TableCell>
                  <TableCell>{valorTriglicerídeos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoTriglicerídeos(
                      parseFloat(valorTriglicerídeos)
                    )}
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          </ResultContainer>
        )}
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form40;
