import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  Button,
  FormInput,
  FormContainer,
  FormGroup,
  FormHeader,
  FormLabel,
  FormSelect,
  DivInputs,
  FormLabelOptions,
  ButtonSend,
} from "../../../Components/all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form21 = () => {
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [q9, setQ9] = useState("");
  const [q10, setQ10] = useState("");
  const [q11, setQ11] = useState("");
  const [q12, setQ12] = useState("");
  const [q13, setQ13] = useState("");
  const [q14, setQ14] = useState("");
  const [q15, setQ15] = useState("");
  const [q16, setQ16] = useState("");
  const [q17, setQ17] = useState("");
  const [q18, setQ18] = useState("");
  const [q19, setQ19] = useState("");
  const [q20, setQ20] = useState("");
  const [q21, setQ21] = useState("");
  const [q22, setQ22] = useState("");
  const [q23, setQ23] = useState("");
  const [q24, setQ24] = useState("");
  const [q25, setQ25] = useState("");
  const [q26, setQ26] = useState("");

  const [results, setResults] = useState({
    perception: null,
    healthSatisfaction: null,
    physicalDomain: null,
    psychologicalDomain: null,
    socialRelationsDomain: null,
    environmentDomain: null,
  });

  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const q3Recoded = 6 - parseInt(q3);
    const q4Recoded = 6 - parseInt(q4);
    const q26Recoded = 6 - parseInt(q26);

    const physicalDomain = average([
      q3Recoded,
      q4Recoded,
      q10,
      q15,
      q16,
      q17,
      q18,
    ]);
    const psychologicalDomain = average([q5, q6, q7, q11, q19, q26Recoded]);
    const socialRelationsDomain = average([q20, q21, q22]);
    const environmentDomain = average([q8, q9, q12, q13, q14, q23, q24, q25]);

    setResults({
      perception: q1,
      healthSatisfaction: q2,
      physicalDomain,
      psychologicalDomain,
      socialRelationsDomain,
      environmentDomain,
    });
    console.log("Domínio Físico:", physicalDomain);
    console.log(
      "Classificação do Domínio de Relações Sociais:",
      classifyScore(socialRelationsDomain)
    );
    console.log(
      "Classificação do Domínio de Ambiente:",
      classifyScore(environmentDomain)
    );

    const formData = {
      id,
      perception: q1,
      healthSatisfaction: q2,
      physicalDomain,
      physicalDomainClassification: classifyScore(physicalDomain),
      psychologicalDomain,
      psychologicalDomainClassification: classifyScore(psychologicalDomain),
      socialRelationsDomain,
      socialRelationsDomainClassification: classifyScore(socialRelationsDomain),
      environmentDomain,
      environmentDomainClassification: classifyScore(environmentDomain),
    };

    const { data, error } = await supabase
      .from("habitosVida")
      .insert([formData]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const average = (values) => {
    const validValues = values.filter((val) => !isNaN(val));
    if (validValues.length === 0) return 0; // ou trate conforme apropriado
    const total = validValues.reduce((acc, val) => acc + parseInt(val), 0);
    return total / validValues.length;
  };

  const classifyScore = (score) => {
    if (score >= 1 && score <= 2.9) return "necessita melhorar";
    if (score >= 3 && score <= 3.9) return "regular";
    if (score >= 4 && score <= 4.9) return "boa";
    if (score === 5) return "muito boa";
    return "Não classificado";
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Saude-e-qualidade-de-vida");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>
            INSTRUMENTO PARA AVALIAÇÃO DA QUALIDADE DE VIDA RELACIONADA A SAÚDE
          </h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabelOptions>
              Este questionário é como o Sr (a) se sente em relação a sua
              qualidade de vida, saúde e outras áreas de sua vida nas duas
              últimas semanas.
              <br /> Após a leitura o Sr (a) deverá escolher a resposta que lhe
              parecer mais adequada para o seu caso.
            </FormLabelOptions>
          </FormGroup>
          <DivInputs>
            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="20.87vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormGroup>
              <FormLabel>Como você avaliaria sua qualidade de vida?</FormLabel>
              <FormSelect value={q1} onChange={(e) => setQ1(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito ruim</option>
                <option value="2">Ruim</option>
                <option value="3">Nem ruim e nem boa</option>
                <option value="4">Boa</option>
                <option value="5">Muito boa</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <FormLabelOptions>
            As questões seguintes são sobre o quanto você tem sentido algumas
            coisas nas duas últimas semanas.
          </FormLabelOptions>
          <DivInputs>
            <FormGroup>
              <FormLabel>O quanto você aproveita a sua vida?</FormLabel>
              <FormSelect value={q5} onChange={(e) => setQ5(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Mais ou menos</option>
                <option value="4">Bastante</option>
                <option value="5">Extremamente</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Em que medida você acha que sua vida tem sentido?
              </FormLabel>
              <FormSelect value={q6} onChange={(e) => setQ6(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Mais ou menos</option>
                <option value="4">Bastante</option>
                <option value="5">Extremamente</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>O quanto você consegue se concentrar?</FormLabel>
              <FormSelect value={q7} onChange={(e) => setQ7(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Mais ou menos</option>
                <option value="4">Bastante</option>
                <option value="5">Extremamente</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                Quão seguro você se sente em sua vida diária?
              </FormLabel>
              <FormSelect value={q8} onChange={(e) => setQ8(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Mais ou menos</option>
                <option value="4">Bastante</option>
                <option value="5">Extremamente</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Quão saudável é o seu ambiente físico (clima, barulho, poluição,
                atrativos)?
              </FormLabel>
              <FormSelect value={q9} onChange={(e) => setQ9(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Mais ou menos</option>
                <option value="4">Bastante</option>
                <option value="5">Extremamente</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                Você tem energia o suficiente para o seu dia-a-dia?
              </FormLabel>
              <FormSelect value={q10} onChange={(e) => setQ10(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Médio</option>
                <option value="4">Muito</option>
                <option value="5">Completamente</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Você é capaz de aceitar sua aparência física?
              </FormLabel>
              <FormSelect value={q11} onChange={(e) => setQ11(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Médio</option>
                <option value="4">Muito</option>
                <option value="5">Completamente</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Você tem dinheiro suficiente para satisfazer suas necessidades?
              </FormLabel>
              <FormSelect value={q12} onChange={(e) => setQ12(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Médio</option>
                <option value="4">Muito</option>
                <option value="5">Completamente</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                Quão disponíveis estão para você as informações que precisa no
                seu dia-a-dia?
              </FormLabel>
              <FormSelect value={q13} onChange={(e) => setQ13(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Médio</option>
                <option value="4">Muito</option>
                <option value="5">Completamente</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Em que medida você tem oportunidades de atividades de lazer?
              </FormLabel>
              <FormSelect value={q14} onChange={(e) => setQ14(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Nada</option>
                <option value="2">Muito pouco</option>
                <option value="3">Médio</option>
                <option value="4">Muito</option>
                <option value="5">Completamente</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <FormLabelOptions>
            As questões seguintes perguntam sobre quão bem ou satisfeito você se
            sentiu a respeito de vários aspectos de sua vida nas últimas duas
            semanas.
          </FormLabelOptions>

          <DivInputs>
            <FormGroup>
              <FormLabel>Quão bem você é capaz de se locomover?</FormLabel>
              <FormSelect value={q15} onChange={(e) => setQ15(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito ruim</option>
                <option value="2">Ruim</option>
                <option value="3">Nem ruim e nem bom</option>
                <option value="4">Bom</option>
                <option value="5">Muito bom</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Quão satisfeito (a) você está com o seu sono?
              </FormLabel>
              <FormSelect value={q16} onChange={(e) => setQ16(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito insatisfeito</option>
                <option value="2">Insatisfeito</option>
                <option value="3">Nem satisfeito e Nem insatisfeito</option>
                <option value="5">Satisfeito</option>
                <option value="6">Muito satisfeito</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <FormGroup>
            <FormLabel>
              Quão satisfeito (a) você está com a sua capacidade de desempenhar
              as atividades do seu dia-a-dia?
            </FormLabel>
            <FormSelect
              value={q17}
              onChange={(e) => setQ17(e.target.value)}
              customWidth="35.87vw"
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Muito insatisfeito</option>
              <option value="2">Insatisfeito</option>
              <option value="3">Nem satisfeito e Nem insatisfeito</option>
              <option value="5">Satisfeito</option>
              <option value="6">Muito satisfeito</option>
            </FormSelect>
          </FormGroup>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                Quão satisfeito (a) você está com sua capacidade para o
                trabalho?
              </FormLabel>
              <FormSelect value={q18} onChange={(e) => setQ18(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito insatisfeito</option>
                <option value="2">Insatisfeito</option>
                <option value="3">Nem satisfeito e Nem insatisfeito</option>
                <option value="5">Satisfeito</option>
                <option value="6">Muito satisfeito</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Quão satisfeito (a) você está consigo mesmo?
              </FormLabel>
              <FormSelect value={q19} onChange={(e) => setQ19(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito insatisfeito</option>
                <option value="2">Insatisfeito</option>
                <option value="3">Nem satisfeito e Nem insatisfeito</option>
                <option value="5">Satisfeito</option>
                <option value="6">Muito satisfeito</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <FormGroup>
            <FormLabel>
              Quão satisfeito (a) você está com suas relações pessoais (amigos,
              parentes, conhecidos, colegas)?
            </FormLabel>
            <FormSelect
              value={q20}
              onChange={(e) => setQ20(e.target.value)}
              customWidth="35.87vw"
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Muito insatisfeito</option>
              <option value="2">Insatisfeito</option>
              <option value="3">Nem satisfeito e Nem insatisfeito</option>
              <option value="5">Satisfeito</option>
              <option value="6">Muito satisfeito</option>
            </FormSelect>
          </FormGroup>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                Quão satisfeito (a) você está com sua vida sexual?
              </FormLabel>
              <FormSelect value={q21} onChange={(e) => setQ21(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito insatisfeito</option>
                <option value="2">Insatisfeito</option>
                <option value="3">Nem satisfeito e Nem insatisfeito</option>
                <option value="5">Satisfeito</option>
                <option value="6">Muito satisfeito</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Quão satisfeito (a) você está com o apoio que recebe dos seus
                amigos?
              </FormLabel>
              <FormSelect value={q22} onChange={(e) => setQ22(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito insatisfeito</option>
                <option value="2">Insatisfeito</option>
                <option value="3">Nem satisfeito e Nem insatisfeito</option>
                <option value="5">Satisfeito</option>
                <option value="6">Muito satisfeito</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                Quão satisfeito (a) você está com as condições do local onde
                mora?
              </FormLabel>
              <FormSelect value={q23} onChange={(e) => setQ23(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito insatisfeito</option>
                <option value="2">Insatisfeito</option>
                <option value="3">Nem satisfeito e Nem insatisfeito</option>
                <option value="5">Satisfeito</option>
                <option value="6">Muito satisfeito</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Quão satisfeito (a) você está com o seu acesso a serviços de
                saúde?
              </FormLabel>
              <FormSelect value={q24} onChange={(e) => setQ24(e.target.value)}>
                <option value="">Selecione uma opção</option>
                <option value="1">Muito insatisfeito</option>
                <option value="2">Insatisfeito</option>
                <option value="3">Nem satisfeito e Nem insatisfeito</option>
                <option value="5">Satisfeito</option>
                <option value="6">Muito satisfeito</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <FormGroup>
            <FormLabel>
              Quão satisfeito (a) você está com o seu meio de transporte?
            </FormLabel>
            <FormSelect
              value={q25}
              onChange={(e) => setQ25(e.target.value)}
              customWidth="35.87vw"
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Muito insatisfeito</option>
              <option value="2">Insatisfeito</option>
              <option value="3">Nem satisfeito e Nem insatisfeito</option>
              <option value="5">Satisfeito</option>
              <option value="6">Muito satisfeito</option>
            </FormSelect>
          </FormGroup>

          <FormLabelOptions>
            As questões seguintes referem-se a com que frequência você sentiu ou
            experimentou certas coisas nas últimas duas semanas.
          </FormLabelOptions>

          <FormGroup>
            <FormLabel>
              Com que frequência você tem sentimentos negativos, tais como mau
              humor, desespero, ansiedade e depressão?
            </FormLabel>
            <FormSelect
              value={q26}
              onChange={(e) => setQ26(e.target.value)}
              customWidth="55.87vw"
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Nunca</option>
              <option value="2">Algumas vezes</option>
              <option value="3">Frequentemente</option>
              <option value="4">Muito frequentemente</option>
              <option value="5">Sempre</option>
            </FormSelect>
          </FormGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>

            <ButtonSend type="submit">Enviar</ButtonSend>
          </div>
        </form>

        <div className="results">
          <h3>Resultados:</h3>

          <p>
            <strong>Domínio Físico:</strong> {} (
            {classifyScore(results.physicalDomain)})
          </p>
          <p>
            <strong>Domínio Psicológico:</strong> {} (
            {classifyScore(results.psychologicalDomain)})
          </p>
          <p>
            <strong>Relações Sociais:</strong> {} (
            {classifyScore(results.socialRelationsDomain)})
          </p>
        </div>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form21;
