import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  FormRadio,
  Table,
  TableHeader,
  TableCell,
  FormInput,
  Button1,
  ResultContainer,
  Button,
  DivInputs,
  FormHeader,
} from "../../../Components/all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form41 = () => {
  const [sexoExames, setSexoExames] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [valorHemoglobina, setValorHemoglobina] = useState("");
  const [valorHematócrito, setValorHematócrito] = useState("");
  const [valorVCM, setValorVCM] = useState("");
  const [valorHCM, setValorHCM] = useState("");
  const [valorAmplitude, setValorAmplitude] = useState("");
  const [valorContagem, setValorContagem] = useState("");
  const [valorCHCM, setValorCHCM] = useState("");
  const [valorEritrócitos, setValorEritrócitos] = useState("");
  const [valorNeutrófilos, setValorNeutrófilos] = useState("");
  const [valorLinfócitos, setValorLinfócitos] = useState("");
  const [valorMonócitos, setValorMonócitos] = useState("");
  const [valorEosinófilos, setValorEosinófilos] = useState("");
  const [valorBasófilos, setValorBasófilos] = useState("");
  const [valorPlaquetas, setValorPlaquetas] = useState("");

  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleGenerateResult = () => {
    setShowResult(true);
  };

  const obterDiagnosticoHCM = (valorHCM) => {
    if (valorHCM >= 28 && valorHCM <= 32) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoHemoglobina = (valorHemoglobina) => {
    if (sexoExames === "Masculino") {
      if (valorHemoglobina >= 140 && valorHemoglobina <= 170) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorHemoglobina >= 120 && valorHemoglobina <= 170) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoCHCM = (valorCHCM) => {
    if (valorCHCM >= 32 && valorCHCM <= 36) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoContagem = (valorContagem) => {
    if (valorContagem >= 4500 && valorContagem <= 11000) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoAmplitude = (valorAmplitude) => {
    if ((valorAmplitude >= 11, 5 && valorAmplitude <= 14, 5)) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoPlaquetas = (valorPlaquetas) => {
    if (valorPlaquetas >= 140000 && valorPlaquetas <= 450000) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoEritrócitos = (valorEritrócitos) => {
    if (sexoExames === "Masculino") {
      if ((valorEritrócitos >= 4, 5 && valorEritrócitos <= 5, 9)) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if ((valorEritrócitos >= 4 && valorEritrócitos <= 5, 2)) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoHematócrito = (valorHematócrito) => {
    if (sexoExames === "Masculino") {
      if (valorHematócrito >= 41 && valorHematócrito <= 51) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorHematócrito >= 36 && valorHematócrito <= 47) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoVCM = (valorVCM) => {
    if (valorVCM >= 80 && valorVCM <= 100) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoNeutrófilos = (valorNeutrófilos) => {
    if (valorNeutrófilos >= 40 && valorNeutrófilos <= 70) {
      return "Dentro do intervalo normal";
    } else if (valorNeutrófilos >= 1800 && valorNeutrófilos <= 7700) {
      return "Dentro do intervalo normal";
    }
    return "Fora do intervalo normal";
  };

  const obterDiagnosticoLinfócitos = (valorLinfócitos) => {
    if (valorLinfócitos >= 22 && valorLinfócitos <= 44) {
      return "Dentro do intervalo normal";
    } else if (valorLinfócitos >= 1000 && valorLinfócitos <= 4800) {
      return "Dentro do intervalo normal";
    }
    return "Fora do intervalo normal";
  };

  const obterDiagnosticoMonócitos = (valorMonócitos) => {
    if (valorMonócitos >= 4 && valorMonócitos <= 11) {
      return "Dentro do intervalo normal";
    } else if (valorMonócitos >= 200 && valorMonócitos <= 1200) {
      return "Dentro do intervalo normal";
    }
    return "Fora do intervalo normal";
  };

  const obterDiagnosticoEosinófilos = (valorEosinófilos) => {
    if (valorEosinófilos >= 0 && valorEosinófilos <= 8) {
      return "Dentro do intervalo normal";
    } else if (valorEosinófilos >= 9 && valorEosinófilos <= 900) {
      return "Dentro do intervalo normal";
    }
    return "Fora do intervalo normal";
  };

  const obterDiagnosticoBasófilos = (valorBasófilos) => {
    if (valorBasófilos >= 0 && valorBasófilos <= 3) {
      return "Dentro do intervalo normal";
    } else if (valorBasófilos >= 4 && valorBasófilos <= 400) {
      return "Dentro do intervalo normal";
    }
    return "Fora do intervalo normal";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      diagnósticoHCM: obterDiagnosticoHCM(parseFloat(valorHCM)),
      diagnósticoHemoglobina: obterDiagnosticoHemoglobina(
        parseFloat(valorHemoglobina)
      ),
      diagnósticoCHCM: obterDiagnosticoCHCM(parseFloat(valorCHCM)),
      diagnósticoEritrócitos: obterDiagnosticoEritrócitos(
        parseFloat(valorEritrócitos)
      ),
      diagnósticoVCM: obterDiagnosticoVCM(parseFloat(valorVCM)),
      diagnósticoHematócrito: obterDiagnosticoHematócrito(
        parseFloat(valorHematócrito)
      ),
      diagnósticoContagem: obterDiagnosticoContagem(parseFloat(valorContagem)),
      diagnósticoAmplitude: obterDiagnosticoAmplitude(
        parseFloat(valorAmplitude)
      ),
      diagnósticoNeutrófilos: obterDiagnosticoNeutrófilos(
        parseFloat(valorNeutrófilos)
      ),
      diagnósticoLinfócitos: obterDiagnosticoLinfócitos(
        parseFloat(valorLinfócitos)
      ),
      diagnósticoMonócitos: obterDiagnosticoMonócitos(
        parseFloat(valorMonócitos)
      ),
      diagnósticoEosinófilos: obterDiagnosticoEosinófilos(
        parseFloat(valorEosinófilos)
      ),
      diagnósticoBasófilos: obterDiagnosticoBasófilos(
        parseFloat(valorBasófilos)
      ),
      diagnósticoPlaquetas: obterDiagnosticoPlaquetas(
        parseFloat(valorPlaquetas)
      ),
    };

    try {
      const { data: inserted, error } = await supabase
        .from("hemograma")
        .insert([data]);
      if (error) {
        throw error;
      }
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    } catch (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    }
  };

  const handleProfileClick = () => {
    navigate("/plataforma/Exames-laboratoriais");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2> HEMOGRANA COMPLETO</h2>
        </FormHeader>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Nº de CPF :</FormLabel>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="22vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Sexo:</FormLabel>
            <FormRadioGroup>
              {["Masculino", "Feminino"].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="sexoExames"
                    value={value}
                    checked={sexoExames === value}
                    onChange={(e) => setSexoExames(e.target.value)}
                  />
                  <span>{value}</span>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <DivInputs>
            <FormGroup>
              <FormLabel>Hemoglobina:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da Hemoglobina"
                value={valorHemoglobina}
                onChange={(e) => setValorHemoglobina(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Hematócrito:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do Hematócrito"
                value={valorHematócrito}
                onChange={(e) => setValorHematócrito(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Volume celular (ou corpuscular) médico (VCM):
              </FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do VCM"
                value={valorVCM}
                onChange={(e) => setValorVCM(e.target.value)}
              />
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>
                {" "}
                Hemoglobina celular (ou corpuscular) média (HCM):
              </FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da HCM"
                value={valorHCM}
                onChange={(e) => setValorHCM(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                {" "}
                Concentração de hemoglobina celular (ou corpuscular) média
                (CHCM):{" "}
              </FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do CHCM"
                value={valorCHCM}
                onChange={(e) => setValorCHCM(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Contagem de glóbulos vermelhos (eritrócitos):
              </FormLabel>
              <FormInput
                type="text"
                placeholder="Valor dos Eritrócitos"
                value={valorEritrócitos}
                onChange={(e) => setValorEritrócitos(e.target.value)}
              />
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>Amplitude de distribuição de eritrócitos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da Amplitude de distribuição de eritrócitos"
                value={valorAmplitude}
                onChange={(e) => setValorAmplitude(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Contagem de glóbulos brancos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor de glóbulos brancos"
                value={valorContagem}
                onChange={(e) => setValorContagem(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Contagem de Neutrófilos segmentados:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor de Neutrófilos segmentados"
                value={valorNeutrófilos}
                onChange={(e) => setValorNeutrófilos(e.target.value)}
              />
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>Contagem de Linfócitos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor de Linfócitos"
                value={valorLinfócitos}
                onChange={(e) => setValorLinfócitos(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Contagem de Monócitos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor de Monócitos"
                value={valorMonócitos}
                onChange={(e) => setValorMonócitos(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Contagem de Eosinófilos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor de Eosinófilos"
                value={valorEosinófilos}
                onChange={(e) => setValorEosinófilos(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Contagem de Basófilos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor de Basófilos"
                value={valorBasófilos}
                onChange={(e) => setValorBasófilos(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Contagem de Plaquetas:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor de Plaquetas"
                value={valorPlaquetas}
                onChange={(e) => setValorPlaquetas(e.target.value)}
              />
            </FormGroup>
          </DivInputs>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>
            <Button1 onClick={handleGenerateResult}>Gerar Resultado</Button1>
          </div>
        </form>

        {showResult && (
          <ResultContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Exame</TableHeader>
                  <TableHeader>Resultado</TableHeader>
                  <TableHeader>Diagnóstico</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>Hemoglobina</TableCell>
                  <TableCell>{valorHemoglobina}</TableCell>
                  <TableCell>
                    {obterDiagnosticoHemoglobina(parseFloat(valorHemoglobina))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Hematócrito</TableCell>
                  <TableCell>{valorHematócrito}</TableCell>
                  <TableCell>
                    {obterDiagnosticoHematócrito(parseFloat(valorHematócrito))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Volume celular (ou corpuscular) médico (VCM)
                  </TableCell>
                  <TableCell>{valorVCM}</TableCell>
                  <TableCell>
                    {obterDiagnosticoVCM(parseFloat(valorVCM))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Concentração de hemoglobina celular (ou corpuscular) média
                    (CHCM)
                  </TableCell>
                  <TableCell>{valorCHCM}</TableCell>
                  <TableCell>
                    {obterDiagnosticoCHCM(parseFloat(valorCHCM))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Contagem de glóbulos vermelhos (eritrócitos)
                  </TableCell>
                  <TableCell>{valorEritrócitos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoEritrócitos(parseFloat(valorEritrócitos))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Hemoglobina celular (ou corpuscular) média (HCM)
                  </TableCell>
                  <TableCell>{valorHCM}</TableCell>
                  <TableCell>
                    {obterDiagnosticoHCM(parseFloat(valorHCM))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>
                    Amplitude de distribuição de eritrócitos
                  </TableCell>
                  <TableCell>{valorAmplitude}</TableCell>
                  <TableCell>
                    {obterDiagnosticoAmplitude(parseFloat(valorAmplitude))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Contagem de glóbulos brancos</TableCell>
                  <TableCell>{valorContagem}</TableCell>
                  <TableCell>
                    {obterDiagnosticoContagem(parseFloat(valorContagem))}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de Neutrófilos</TableCell>
                  <TableCell>{valorNeutrófilos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoNeutrófilos(parseFloat(valorNeutrófilos))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Contagem de Linfócitos</TableCell>
                  <TableCell>{valorLinfócitos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoLinfócitos(parseFloat(valorLinfócitos))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Contagem de Monócitos</TableCell>
                  <TableCell>{valorMonócitos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoMonócitos(parseFloat(valorMonócitos))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Contagem de Eosinófilos</TableCell>
                  <TableCell>{valorEosinófilos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoEosinófilos(parseFloat(valorEosinófilos))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Contagem de Basófilos</TableCell>
                  <TableCell>{valorBasófilos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoBasófilos(parseFloat(valorBasófilos))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Contagem de Plaquetas</TableCell>
                  <TableCell>{valorPlaquetas}</TableCell>
                  <TableCell>
                    {obterDiagnosticoPlaquetas(parseFloat(valorPlaquetas))}
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          </ResultContainer>
        )}
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form41;
