import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  border: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  width: 100vw;
`;

export const DivImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
`;

export const RightImage = styled.img`
  max-width: 18.23vw;
  height: auto;
  margin-left: 4vw;
  margin-bottom: 1vh;
`;

export const FormContainer = styled.div`
  width: 90vw;
`;

export const FormGroup = styled.div`
  margin-bottom: 25px;
`;

export const DivInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormInputCpf = styled.input`
  width: ${(props) => props.customWidth || "22vw"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const FormLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 18px;
  margin-top: 18px;
  display: block;
  text-align: left;
`;

export const FormHeader = styled.div`
  margin-top: 40px;
  text-align: start;
  margin-bottom: 20px;
  color: #1f1087;
`;

export const FormInput = styled.input`
  width: ${(props) => props.customWidth || "10vw"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const FormRadioGroup = styled.div`
  display: flex;
  gap: 10px;
  font-size: 1rem;
`;

export const FormRadio = styled.input`
  margin-right: 5px;
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #2e19bd;
  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #b8b8b8;
  color: black;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  &:hover {
    transform: scale(1.09);
  }
`;
