import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 70vh;
  background-color: #fff;
  border: none;
  overflow: hidden;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 50px;
`;

export const UserInfo = styled.p`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  color: #555;
`;

export const FormLabel = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
`;

export const FormInput = styled.input`
  width: 28vw;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

export const SairButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #b8b8b8;
  color: #fff;
  width: 5vw;
  height: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
`;
export const FormButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #30a46c;
  color: #fff;
  width: 9vw;
  height: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
`;

export const CartaoButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #1f1087;
  color: #fff;
  width: 14vw;
  height: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
`;

export const TopImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const TopImage = styled.img`
  max-width: 150px;
  max-height: 100px;
  border-radius: 10px;
`;

export const WelcomeText = styled.p`
  font-weight: 700;
  font-size: 42px;
  color: #2614a0;
  margin-bottom: 40px;
  margin-left: 50px;
`;
