import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  width: 100vw;
`;

export const DivImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
`;

export const RightImage = styled.img`
  max-width: 18.23vw;
  height: auto;
  margin-left: 4vw;
  margin-bottom: 1vh;
`;

export const FormContainer = styled.div`
  width: 95vw;
`;

export const FormGroup = styled.div`
  margin-bottom: 25px;
`;

export const FormHeader = styled.div`
  margin-top: 40px;
  text-align: start;
  margin-bottom: 20px;
  color: #1f1087;
`;

export const FormLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  font-size: 1.2rem;
  text-align: justify;
  color: #333;
`;

export const StyledTextarea = styled.textarea`
  width: 42vw;
  height: 20vh;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 8px;
  border: 1px solid #b8b8b8;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s, box-shadow 0.3s;

  &::placeholder {
    color: #a5a5a5;
  }
`;

export const FormInput = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const StyledInput = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #2e19bd;
  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #b8b8b8;
  color: black;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  &:hover {
    transform: scale(1.09);
  }
`;

export const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  cursor: pointer;

  input {
    margin-right: 8px;
  }
`;

export const RadioLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  cursor: pointer;

  input {
    margin-right: 8px;
  }
`;
