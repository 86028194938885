import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  Table,
  TableHeader,
  TableCell,
  FormInput,
  FormRadio,
  Button1,
  ResultContainer,
  Button,
  DivInputs,
  FormHeader,
} from "../../../Components/all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form39 = () => {
  const [sexoExames, setSexoExames] = useState("");
  const [idadeExames, setIdadeExames] = useState("");
  const [colesterolHDL, setColesterolHDL] = useState("");
  const [pressaoArterial, setPressaoArterial] = useState("");
  const [glicemia, setGlicemia] = useState("");
  const [circunferenciaCintura, setCircunferenciaCintura] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [hasAbdominalObesity, setHasAbdominalObesity] = useState(false);
  const [hasLowHDL, setHasLowHDL] = useState(false);
  const [hasHighTriglycerides, setHasHighTriglycerides] = useState(false);
  const [hasHighBloodPressure, setHasHighBloodPressure] = useState(false);
  const [hasHighGlucose, setHasHighGlucose] = useState(false);
  const [hasInsulinResistance, setHasInsulinResistance] = useState(false);
  const [valorTotalColesterol, setValorTotalColesterol] = useState("");
  const [valorPCR, setValorPCR] = useState("");
  const [valorAST, setValorAST] = useState("");
  const [valorALT, setValorALT] = useState("");
  const [valorLDL, setValorLDL] = useState("");
  const [valorLDH, setValorLDH] = useState("");
  const [valorHDL, setValorHDL] = useState("");
  const [valorCK, setValorCK] = useState("");
  const [valorVHS, setValorVHS] = useState("");
  const [valorVLDL, setValorVLDL] = useState("");
  const [valorGlicemia, setValorGlicemia] = useState("");
  const [valorFerritina, setValorFerritina] = useState("");
  const [valorCreatinina, setValorCreatinina] = useState("");
  const [valorTriglicerídeos, setValorTriglicerídeos] = useState("");
  const [valorUreia, setValorUreia] = useState("");

  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleGenerateResult = () => {
    const glicemiaDiagnostico = obterDiagnosticoGlicemia(
      parseFloat(valorGlicemia)
    );
    const ldlDiagnostico = obterDiagnosticoLDL(parseFloat(valorLDL));
    const vldlDiagnostico = obterDiagnosticoVLDL(parseFloat(valorVLDL));
    const colesterolTotalDiagnostico = obterDiagnosticoColesterolTotal(
      parseFloat(valorTotalColesterol)
    );
    const ferritinaDiagnostico = obterDiagnosticoFerritina(
      parseFloat(valorFerritina)
    );
    const creatininaDiagnostico = obterDiagnosticoCreatinina(
      parseFloat(valorCreatinina)
    );
    const ureiaDiagnostico = obterDiagnosticoUreia(parseFloat(valorUreia));

    const abdominalObesity =
      (sexoExames === "Masculino" && Number(circunferenciaCintura) > 102) ||
      (sexoExames === "Feminino" && Number(circunferenciaCintura) > 88);

    const lowHDL =
      (sexoExames === "Masculino" && Number(colesterolHDL) < 40) ||
      (sexoExames === "Feminino" && Number(colesterolHDL) < 50);

    const highTriglycerides = Number(glicemia) >= 150;

    const pressaoArterialParts = pressaoArterial.split("/");
    const highBloodPressure =
      pressaoArterial &&
      pressaoArterial.includes("/") &&
      (Number(pressaoArterialParts[0]) >= 135 ||
        Number(pressaoArterialParts[1]) >= 85);

    const highGlucose = Number(glicemia) >= 110;

    const insulinResistance =
      (abdominalObesity ? 1 : 0) +
        (lowHDL ? 1 : 0) +
        (highTriglycerides ? 1 : 0) +
        (highBloodPressure ? 1 : 0) +
        (highGlucose ? 1 : 0) >=
      3;

    setShowResult(true);
    setHasAbdominalObesity(abdominalObesity);
    setHasLowHDL(lowHDL);
    setHasHighTriglycerides(highTriglycerides);
    setHasHighBloodPressure(highBloodPressure);
    setHasHighGlucose(highGlucose);
    setHasInsulinResistance(insulinResistance);
  };

  const obterDiagnosticoGlicemia = (valorGlicemia) => {
    if (valorGlicemia < 100) {
      return "Glicemia de jejum normal.";
    } else if (valorGlicemia >= 100 && valorGlicemia < 126) {
      return "Glicemia de jejum alterada. Risco de resistência insulínica.";
    } else if (valorGlicemia >= 126) {
      return "Diabetes. Risco elevado de resistência insulínica.";
    } else if (valorGlicemia <= 70) {
      return "Glicemia de jejum baixa ou hipoglicemia.";
    }
    return "";
  };

  const obterDiagnosticoLDL = (valorLDL) => {
    if (valorLDL <= 130) {
      return "Risco baixo de doença cardiovascular.";
    } else if (valorLDL <= 100) {
      return "Risco médio de doença cardiovascular.";
    } else if (valorLDL <= 70) {
      return "Risco alto de doença cardiovascular.";
    } else if (valorLDL <= 50) {
      return "Risco muito alto de doença cardiovascular.";
    }
    return "";
  };

  const obterDiagnosticoLDH = (valorLDH) => {
    if (valorLDH >= 207 && valorLDH <= 414) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoVLDL = (valorVLDL) => {
    if (valorVLDL > 40) {
      return "Nível elevado de VLDL. Risco de doença cardiovascular.";
    } else if (valorVLDL <= 30) {
      return "Nível ideal de VLDL.";
    } else if (valorVLDL < 30 && valorVLDL > 0) {
      return "Nível baixo de VLDL.";
    }
    return "";
  };

  const obterDiagnosticoFerritina = (valorFerritina) => {
    if (sexoExames === "Masculino") {
      if (valorFerritina >= 20 && valorFerritina <= 250) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorFerritina >= 20 && valorFerritina <= 200) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoCreatinina = (valorCreatinina) => {
    if (sexoExames === "Masculino") {
      if (valorCreatinina >= 0.7 && valorCreatinina <= 1.3) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorCreatinina >= 0.5 && valorCreatinina <= 1.0) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoUreia = (valorUreia) => {
    if (valorUreia >= 10 && valorUreia <= 45) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoALT = (valorALT) => {
    if (valorALT >= 7 && valorALT <= 56) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoAST = (valorAST) => {
    if (valorAST >= 10 && valorAST <= 40) {
      return "Dentro do intervalo normal";
    } else {
      return "Fora do intervalo normal";
    }
  };

  const obterDiagnosticoColesterolTotal = (valorTotalColesterol) => {
    if (valorTotalColesterol < 200) {
      return "Desejável";
    } else if (valorTotalColesterol >= 200 && valorTotalColesterol < 240) {
      return "Limítrofe";
    } else if (valorTotalColesterol >= 240) {
      return "Alto";
    }
  };

  const obterDiagnosticoTriglicerídeos = (valorTriglicerídeos) => {
    if (valorTriglicerídeos <= 150) {
      return "Desejável";
    } else if (valorTriglicerídeos >= 151 && valorTriglicerídeos <= 199) {
      return "Limítrofe";
    } else if (valorTriglicerídeos >= 200 && valorTriglicerídeos <= 499) {
      return "Alto";
    } else if (valorTriglicerídeos >= 500) {
      return "Muito alto";
    }
    return "";
  };

  const obterDiagnosticoHDL = (valorHDL) => {
    if (sexoExames === "Masculino") {
      if (valorHDL <= 40) {
        return "Nível baixo, risco aumentado";
      } else if (valorHDL >= 41 && valorHDL <= 50) {
        return "Nível médio, risco médio";
      } else if (valorHDL >= 60) {
        return "Nível alto, risco baixo";
      }
    } else if (sexoExames === "Feminino") {
      if (valorHDL <= 50) {
        return "Nível baixo, risco aumentado";
      } else if (valorHDL >= 51 && valorHDL <= 59) {
        return "Nível médio, risco médio";
      } else if (valorHDL >= 60) {
        return "Nível alto, risco baixo";
      }
    }
    return "";
  };

  const obterDiagnosticoVHS = (valorVHS) => {
    if (sexoExames === "Masculino") {
      if (valorCK <= 20) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorCK <= 10) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const obterDiagnosticoCK = (valorCK) => {
    if (sexoExames === "Masculino") {
      if (valorCK <= 174) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    } else if (sexoExames === "Feminino") {
      if (valorCK <= 140) {
        return "Dentro do intervalo normal";
      } else {
        return "Fora do intervalo normal";
      }
    }
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      id,
      diagnósticoGlicemia: obterDiagnosticoGlicemia(parseFloat(valorGlicemia)),
      diagnósticoLDL: obterDiagnosticoLDL(parseFloat(valorLDL)),
      diagnósticoLDH: obterDiagnosticoLDH(parseFloat(valorLDH)),
      diagnósticoHDL: obterDiagnosticoHDL(parseFloat(valorHDL)),
      diagnósticoVLDL: obterDiagnosticoVLDL(parseFloat(valorVLDL)),
      diagnósticoFerritina: obterDiagnosticoFerritina(
        parseFloat(valorFerritina)
      ),
      diagnósticoCK: obterDiagnosticoCK(parseFloat(valorCK)),
      diagnósticoVHS: obterDiagnosticoVHS(parseFloat(valorVHS)),
      diagnósticoCreatinina: obterDiagnosticoCreatinina(
        parseFloat(valorCreatinina)
      ),
      diagnósticoUreia: obterDiagnosticoUreia(parseFloat(valorUreia)),
      diagnósticoALT: obterDiagnosticoALT(parseFloat(valorALT)),
      diagnósticoAST: obterDiagnosticoAST(parseFloat(valorAST)),
      diagnósticoTriglicerídeos: obterDiagnosticoTriglicerídeos(
        parseFloat(valorTriglicerídeos)
      ),
      diagnósticoColesterolTotal: obterDiagnosticoColesterolTotal(
        parseFloat(valorTotalColesterol)
      ),
    };

    try {
      const { data: inserted, error } = await supabase
        .from("examesBioComp")
        .insert([data]);
      if (error) {
        throw error;
      }
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    } catch (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Exames-laboratoriais");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2> EXAMES BIOQUÍMICOS COMPLETO </h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Nº de CPF :</FormLabel>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="22.87vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Sexo:</FormLabel>
            <FormRadioGroup>
              {["Masculino", "Feminino"].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="sexoExames"
                    value={value}
                    checked={sexoExames === value}
                    onChange={(e) => setSexoExames(e.target.value)}
                  />
                  <span>{value}</span>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Idade:</FormLabel>
            <FormRadioGroup>
              {[
                "18 a 29 anos",
                "30 a 40 anos",
                "41 a 51 anos",
                "52 a 62 anos",
                "Acima de 62 anos",
              ].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="idadeExames"
                    value={value}
                    checked={idadeExames === value}
                    onChange={(e) => setIdadeExames(e.target.value)}
                  />
                  <span>{value}</span>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <DivInputs>
            <FormGroup>
              <FormLabel>Proteína C reativa (PCR):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da PCR"
                value={valorPCR}
                onChange={(e) => setValorPCR(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Velocidade de Hemossedimentação (VHS):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da Velocidade de Hemossedimentação (VHS)"
                value={valorVHS}
                onChange={(e) => setValorVHS(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Aspartato Aminotransferase (AST):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da AST"
                value={valorAST}
                onChange={(e) => setValorAST(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Alanina Aminotransferase (ALT):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da ALT"
                value={valorALT}
                onChange={(e) => setValorALT(e.target.value)}
              />
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>Creatinoquinase (CK):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor Creatinoquinase (CK)"
                value={valorCK}
                onChange={(e) => setValorCK(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Lactato Desidrogenase (LDH):</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do Lactato Desidrogenase (LDH)"
                value={valorLDH}
                onChange={(e) => setValorLDH(e.target.value)}
                customWidth="16.87vw"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Ferritina:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da ferritina"
                value={valorFerritina}
                onChange={(e) => setValorFerritina(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Creatinina:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da creatinina"
                value={valorCreatinina}
                onChange={(e) => setValorCreatinina(e.target.value)}
              />
            </FormGroup>
          </DivInputs>

          <DivInputs>
            <FormGroup>
              <FormLabel>Ureia:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da ureia"
                value={valorUreia}
                onChange={(e) => setValorUreia(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Glicemia:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor da glicemia"
                value={valorGlicemia}
                onChange={(e) => setValorGlicemia(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Colesterol Total:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do colesterol total"
                value={valorTotalColesterol}
                onChange={(e) => setValorTotalColesterol(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Colesterol LDL:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do LDL"
                value={valorLDL}
                onChange={(e) => setValorLDL(e.target.value)}
              />
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>Colesterol HDL:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do HDL"
                value={valorHDL}
                onChange={(e) => setValorHDL(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Colesterol VLDL:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor do VLDL"
                value={valorVLDL}
                onChange={(e) => setValorVLDL(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Triglicerídeos:</FormLabel>
              <FormInput
                type="text"
                placeholder="Valor dos Triglicerídeos"
                value={valorTriglicerídeos}
                onChange={(e) => setValorTriglicerídeos(e.target.value)}
              />
            </FormGroup>
          </DivInputs>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>

            <Button1 onClick={handleGenerateResult}>Gerar Resultado</Button1>
          </div>
        </form>

        {showResult && (
          <ResultContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Exame</TableHeader>
                  <TableHeader>Resultado</TableHeader>
                  <TableHeader>Diagnóstico</TableHeader>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <TableCell>VHS</TableCell>
                  <TableCell>{valorVHS}</TableCell>
                  <TableCell>
                    {obterDiagnosticoVHS(parseFloat(valorVHS))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>AST (TGO)</TableCell>
                  <TableCell>{valorAST}</TableCell>
                  <TableCell>
                    {obterDiagnosticoAST(parseFloat(valorAST))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>ALT (TGP)</TableCell>
                  <TableCell>{valorALT}</TableCell>
                  <TableCell>
                    {obterDiagnosticoALT(parseFloat(valorALT))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Creatinoquinase (CK)</TableCell>
                  <TableCell>{valorCK}</TableCell>
                  <TableCell>
                    {obterDiagnosticoCK(parseFloat(valorCK))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>LDH</TableCell>
                  <TableCell>{valorLDH}</TableCell>
                  <TableCell>
                    {obterDiagnosticoLDH(parseFloat(valorLDH))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Ferritina</TableCell>
                  <TableCell>{valorFerritina}</TableCell>
                  <TableCell>
                    {obterDiagnosticoFerritina(parseFloat(valorFerritina))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Creatinina</TableCell>
                  <TableCell>{valorCreatinina}</TableCell>
                  <TableCell>
                    {obterDiagnosticoCreatinina(parseFloat(valorCreatinina))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Glicemia</TableCell>
                  <TableCell>{valorGlicemia}</TableCell>
                  <TableCell>
                    {obterDiagnosticoGlicemia(parseFloat(valorGlicemia))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Ureia</TableCell>
                  <TableCell>{valorUreia}</TableCell>
                  <TableCell>
                    {obterDiagnosticoUreia(parseFloat(valorUreia))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Glicemia</TableCell>
                  <TableCell>{valorGlicemia}</TableCell>
                  <TableCell>
                    {obterDiagnosticoGlicemia(parseFloat(valorGlicemia))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Colesterol Total</TableCell>
                  <TableCell>{valorTotalColesterol}</TableCell>
                  <TableCell>
                    {obterDiagnosticoColesterolTotal(
                      parseFloat(valorTotalColesterol)
                    )}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>LDL</TableCell>
                  <TableCell>{valorLDL}</TableCell>
                  <TableCell>
                    {obterDiagnosticoLDL(parseFloat(valorLDL))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>VLDL</TableCell>
                  <TableCell>{valorVLDL}</TableCell>
                  <TableCell>
                    {obterDiagnosticoVLDL(parseFloat(valorVLDL))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>HDL</TableCell>
                  <TableCell>{valorHDL}</TableCell>
                  <TableCell>
                    {obterDiagnosticoHDL(parseFloat(valorHDL))}
                  </TableCell>
                </tr>

                <tr>
                  <TableCell>Triglicerídeos</TableCell>
                  <TableCell>{valorTriglicerídeos}</TableCell>
                  <TableCell>
                    {obterDiagnosticoTriglicerídeos(
                      parseFloat(valorTriglicerídeos)
                    )}
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          </ResultContainer>
        )}
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form39;
