import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Button,
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  FilterButton,
  FormHeader,
  FormInput,
  FormRadio,
  ResultContainer,
  ResultValue,
  ResultStatus,
  CalculateButton,
  FormGroupIntro,
  FormLabelIntro,
  DivInfos,
  Infos,
} from "./styles";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

function calcularMedia(valores) {
  const valoresNumericos = valores.map((valor) => parseFloat(valor));
  const total = valoresNumericos.reduce((acc, valor) => acc + valor, 0);
  return total / valoresNumericos.length;
}

const Form12 = () => {
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [q9, setQ9] = useState("");
  const [q10, setQ10] = useState("");
  const [q11, setQ11] = useState("");
  const [q12, setQ12] = useState("");
  const [q13, setQ13] = useState("");
  const [q14, setQ14] = useState("");
  const [q15, setQ15] = useState("");
  const [q16, setQ16] = useState("");
  const [q17, setQ17] = useState("");
  const [q18, setQ18] = useState("");
  const [q19, setQ19] = useState("");
  const [q20, setQ20] = useState("");
  const [q21, setQ21] = useState("");
  const [q22, setQ22] = useState("");
  const [q23, setQ23] = useState("");
  const [mediaGeral, setMediaGeral] = useState(null);
  const [roleWorkEnvironmentMedia, setRoleWorkEnvironmentMedia] =
    useState(null);
  const [
    isRoleWorkEnvironmentFilterActive,
    setIsRoleWorkEnvironmentFilterActive,
  ] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("autonomyControl");
  const [autonomyControlFilterActive, setAutonomyControlFilterActive] =
    useState(false);
  const [roleWorkEnvironmentFilterActive, setRoleWorkEnvironmentFilterActive] =
    useState(false);
  const [relationshipWithSupervisorMedia, setRelationshipWithSupervisorMedia] =
    useState(null);
  const [
    relationshipWithSupervisorAnswers,
    setRelationshipWithSupervisorAnswers,
  ] = useState();
  const [
    relacionamentosInterpessoaisMedia,
    setRelacionamentosInterpessoaisMedia,
  ] = useState(null);
  const [crescimentoValoraçãoMedia, setCrescimentoValoraçãoMedia] =
    useState(null);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, ""); // Mantém apenas números
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const updateData = async (formData) => {
    const { data, error } = await supabase
      .from("estresseTrabalho")
      .update(formData)
      .eq("id", id);

    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const insertData = async (formData) => {
    const { data, error } = await supabase
      .from("estresseTrabalho")
      .insert([formData]);

    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      id,
      mediaGeral,
      nivelDeEstresseMediaGeral: determinarNivelDeEstresse(mediaGeral),
      roleWorkEnvironmentMedia,
      nivelDeEstresseRoleWorkEnvironment: determinarNivelDeEstresse(
        roleWorkEnvironmentMedia
      ),
      autonomyControlMedia,
      nivelDeEstresseAutonomyControl:
        determinarNivelDeEstresse(autonomyControlMedia),
      relationshipWithSupervisorMedia,
      nivelDeEstresseRelationshipWithSupervisor: determinarNivelDeEstresse(
        relationshipWithSupervisorMedia
      ),
      relacionamentosInterpessoaisMedia,
      nivelDeEstresseRelacionamentosInterpessoais: determinarNivelDeEstresse(
        relacionamentosInterpessoaisMedia
      ),
      crescimentoValoraçãoMedia,
      nivelDeEstresseCrescimentoValoração: determinarNivelDeEstresse(
        crescimentoValoraçãoMedia
      ),
    };

    const { data, error } = await supabase
      .from("estresseTrabalho")
      .insert([formData]);

    if (error) {
    } else {
      // toast.success(
      //   <SuccessToast>
      //     <strong>Sucesso!</strong> Dados inseridos com êxito.
      //   </SuccessToast>
      // );
    }

    const { data: existingData, error: fetchError } = await supabase
      .from("estresseTrabalho")
      .select("id")
      .eq("id", id);

    if (fetchError) {
      toast.error(
        <ErrorToast>
          <strong>Erro! </strong>
        </ErrorToast>
      );
      return;
    }

    if (existingData.length > 0) {
      updateData(formData);
    } else {
      insertData(formData);
    }
  };

  const toggleAutonomyControlFilter = () => {
    setIsAutonomyControlFilterActive(!isAutonomyControlFilterActive);
  };

  const toggleRoleWorkEnvironmentFilter = () => {
    setIsRoleWorkEnvironmentFilterActive(!isRoleWorkEnvironmentFilterActive);
  };

  const calcularMediaGeral = () => {
    const respostas = [
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      q9,
      q10,
      q11,
      q12,
      q13,
      q14,
      q15,
      q16,
      q17,
      q18,
      q19,
      q20,
      q21,
      q22,
      q23,
    ];
    const valoresNumericos = respostas.map((valor) => parseFloat(valor));
    if (valoresNumericos.some(isNaN)) {
      return;
    }
    if (respostas.some((resposta) => resposta === "")) {
      return;
    }
    const media = calcularMedia(valoresNumericos);
    setMediaGeral(media);
  };

  const [isAutonomyControlFilterActive, setIsAutonomyControlFilterActive] =
    useState(false);
  const [autonomyControlMedia, setAutonomyControlMedia] = useState(null);

  const calcularAutonomyControlMedia = () => {
    const selectedQuestions = [q1, q6, q13, q22, q2];
    const valoresNumericos = selectedQuestions.map((valor) =>
      parseFloat(valor)
    );
    if (valoresNumericos.some(isNaN)) {
      return;
    }
    const media = calcularMedia(valoresNumericos);
    setAutonomyControlMedia(media);
  };

  const calcularRelationshipWithSupervisorMedia = () => {
    const q12Value = parseFloat(q12);
    const q15Value = parseFloat(q15);
    const q20Value = parseFloat(q20);
    const q4Value = parseFloat(q4);
    const q11Value = parseFloat(q11);
    if (
      isNaN(q12Value) ||
      isNaN(q15Value) ||
      isNaN(q20Value) ||
      isNaN(q4Value) ||
      isNaN(q11Value)
    ) {
      return;
    }

    const total = q12Value + q15Value + q20Value + q4Value + q11Value;
    const media = total / 5;
    setRelationshipWithSupervisorMedia(media);
    setRelationshipWithSupervisorAnswers({
      q12: q12Value,
      q15: q15Value,
      q20: q20Value,
      q4: q4Value,
      q11: q11Value,
    });
  };

  const setRelacionamentoChefeMedia = (media) => {
    setRelationshipWithSupervisorMedia(media);
  };

  const calcularRoleWorkEnvironmentMedia = () => {
    const selectedQuestions = [q1, q2, q3, q4, q5];
    const valoresNumericos = selectedQuestions.map((valor) =>
      parseFloat(valor)
    );
    if (valoresNumericos.some(isNaN)) {
      return;
    }

    const media = calcularMedia(valoresNumericos);
    setRoleWorkEnvironmentMedia(media);
  };

  const calcularRelacionamentosInterpessoaisMedia = () => {
    const selectedQuestions = [q18, q7, q14];

    const valoresNumericos = selectedQuestions.map((valor) =>
      parseFloat(valor)
    );

    if (valoresNumericos.some(isNaN)) {
      return;
    }

    const media = calcularMedia(valoresNumericos);
    setRelacionamentosInterpessoaisMedia(media);
  };

  const calcularCrescimentoValoraçãoMedia = () => {
    const selectedQuestions = [q16, q21, q17, q23];

    const valoresNumericos = selectedQuestions.map((valor) =>
      parseFloat(valor)
    );

    if (valoresNumericos.some(isNaN)) {
      return;
    }

    const media = calcularMedia(valoresNumericos);
    setCrescimentoValoraçãoMedia(media);
  };

  const handleCalculate = (filter) => {
    switch (filter) {
      case "autonomyControl":
        calcularAutonomyControlMedia();
        break;
      case "roleWorkEnvironment":
        calcularRoleWorkEnvironmentMedia();
        break;
      case "relationshipWithSupervisor":
        calcularRelationshipWithSupervisorMedia();
        break;
      case "relacionamentosInterpessoais":
        calcularRelacionamentosInterpessoaisMedia();
        break;
      case "crescimentoValoração":
        calcularCrescimentoValoraçãoMedia();
        break;
      default:
        break;
    }
  };

  const getFilterLabel = (filter) => {
    switch (filter) {
      case "autonomyControl":
        return "Autonomia e Controle";
      case "roleWorkEnvironment":
        return "Papéis e Ambiente de Trabalho";
      case "relationshipWithSupervisor":
        return "Relacionamento com o Chefe";
      case "relacionamentosInterpessoais":
        return "Relacionamentos Interpessoais";
      case "crescimentoValoração":
        return "Crescimento e Valorização";
      default:
        return "";
    }
  };

  const determinarNivelDeEstresse = (media) => {
    if (media >= 1 && media <= 2) {
      return "NÍVEL DE ESTRESSE PERCEBIDO: BAIXO";
    } else if (media > 2 && media <= 2.99) {
      return "NÍVEL DE ESTRESSE PERCEBIDO: MÉDIO A ALTO";
    } else {
      return "NÍVEL DE ESTRESSE PERCEBIDO: MUITO ALTO";
    }
  };

  const handleCalculateGeral = () => {
    calcularMediaGeral();
    calcularAutonomyControlMedia();
    calcularRelationshipWithSupervisorMedia();
    calcularRoleWorkEnvironmentMedia();
    calcularRelacionamentosInterpessoaisMedia();
    calcularCrescimentoValoraçãoMedia();
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Estresse-laboral");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>ESCALA DE ESTRESSE NO TRABALHO</h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <FormGroupIntro>
            <FormLabelIntro>
              Leia com atenção as informações contidas neste documento: Este
              questionário possui itens afirmativos que deverão ser assinalados
              conforme sua intensidade corresponderem à resposta que você quer
              dar
            </FormLabelIntro>
          </FormGroupIntro>

          <FormGroupIntro>
            <FormLabelIntro>Nº de CPF :</FormLabelIntro>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="21.87vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroupIntro>

          <DivInfos>
            <Infos>Discordo totalmente</Infos>
            <Infos>Discordo</Infos>
            <Infos>Concordo em parte</Infos>
            <Infos>Concordo</Infos>
            <Infos>Concordo totalmente</Infos>
            <Infos>Prefiro não responder</Infos>
          </DivInfos>

          <FormGroup>
            <FormLabel>
              A forma como as tarefas são distribuídas em minha área tem me
              deixado nervoso.
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q1"
                    value={value.toString()}
                    onChange={(e) => setQ1(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              O tipo de controle existente em meu trabalho me irrita.
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q2"
                    value={value.toString()}
                    onChange={(e) => setQ2(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              A falta de autonomia na execução do meu trabalho tem sido
              desgastante.
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q3"
                    value={value.toString()}
                    onChange={(e) => setQ3(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tenho me sentido incomodado com a falta de confiança de meu
              superior sobre o meu trabalho.
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q4"
                    value={value.toString()}
                    onChange={(e) => setQ4(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me irritado com a deficiência na divulgação de informações
              sobre decisões organizacionais.
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q5"
                    value={value.toString()}
                    onChange={(e) => setQ5(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me incomodado com a falta de informações sobre minhas
              tarefas no trabalho.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q6"
                    value={value.toString()}
                    onChange={(e) => setQ6(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              A falta de comunicação entre mim e meus colegas de trabalho
              deixa-me irritado.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value={value.toString()}
                    onChange={(e) => setQ7(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me incomodado por meu superior tratar-me mal na frente de
              colegas de trabalho.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q8"
                    value={value.toString()}
                    onChange={(e) => setQ8(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me incomodado por ter que realizar tarefas que estão além de
              minha capacidade.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q9"
                    value={value.toString()}
                    onChange={(e) => setQ9(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Fico de mau humor por ter que trabalhar durante muitas horas
              seguidas.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q10"
                    value={value.toString()}
                    onChange={(e) => setQ10(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me incomodado com a comunicação existente entre mim e meu
              superior.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q11"
                    value={value.toString()}
                    onChange={(e) => setQ11(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Fico irritado com discriminação/favoritismo no meu ambiente de
              trabalho.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q12"
                    value={value.toString()}
                    onChange={(e) => setQ12(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tenho me sentido incomodado com a deficiência nos treinamentos
              para capacitação professional.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q13"
                    value={value.toString()}
                    onChange={(e) => setQ13(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Fico de mau humor por me sentir isolado na organização.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q14"
                    value={value.toString()}
                    onChange={(e) => setQ14(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Fico irritado por ser pouco valorizado por meus superiores.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q15"
                    value={value.toString()}
                    onChange={(e) => setQ15(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              As poucas perspectivas de crescimento na carreira têm me deixado
              angustiado.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q16"
                    value={value.toString()}
                    onChange={(e) => setQ16(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tenho me sentido incomodado por trabalhar em tarefas abaixo do meu
              nível de habilidade.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q17"
                    value={value.toString()}
                    onChange={(e) => setQ17(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              A competição no meu ambiente de trabalho tem me deixado de mau
              humor.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q18"
                    value={value.toString()}
                    onChange={(e) => setQ18(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              A falta de compreensão sobre quais são minhas responsabilidades
              neste trabalho tem.causado irritação.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q19"
                    value={value.toString()}
                    onChange={(e) => setQ19(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tenho estado nervoso por meu superior me dar ordens
              contraditórias.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q20"
                    value={value.toString()}
                    onChange={(e) => setQ20(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sinto-me irritado por meu superior encobrir meu trabalho bem feito
              diante de outras pessoas.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q21"
                    value={value.toString()}
                    onChange={(e) => setQ21(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              O tempo insuficiente para realizar meu volume de trabalho deixa-me
              nervoso.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q22"
                    value={value.toString()}
                    onChange={(e) => setQ22(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Fico incomodado por meu superior evitar me incumbir de
              responsabilidades importantes.{" "}
            </FormLabel>
            <FormRadioGroup>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q23"
                    value={value.toString()}
                    onChange={(e) => setQ23(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
              marginBottom: 30,
              marginTop: 30,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>
            <CalculateButton onClick={handleCalculateGeral}>
              Calcular Média Geral
            </CalculateButton>
          </div>
        </form>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form12;
