import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image32 from "../../../Images/image32.png";
import image33 from "../../../Images/image33.png";
import image34 from "../../../Images/image34.png";
import image35 from "../../../Images/image35.png";
import image36 from "../../../Images/image36.png";

import HeaderDiagnostico from "../../../Components/headerDiagnostico";

const images = [
  image32,
  image33,
  image34,
  image35,
  image36,
  image32,
  image32,
  image32,
  image32,
  image32,
  image32,
  image32,
  image32,
];
const cardTitles = [
  "MÉDICO",
  "NUTRICIONISTA",
  "PSICÓLOGO",
  "PROFISSIONAL DE EDUCAÇÃO FÍSICA",
  "DIAGNÓSTICO GERAL DA SAÚDE",
  "ENFERMEIRO",
  "FISIOTERAPEUTA",
  "CIRURGIÃO DENTISTA",
  "MÉDICO PSQUIATRA",
  "MÉDICO CARDIOLOGISTA",
  "MÉDICO ORTOPEDISTA",
  "MÉDICO OFTAMOLOGISTA",
  "MÉDICO CLÍNICO GERAL",
];

const Form30 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Diagnostico-Medico`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Diagnostico-Nutricionista`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Diagnostico-Psicologo`);
    } else if (cardNumber === 4) {
      navigate(`/plataforma/Diagnostico-Profissional-Ed-Fisica`);
    } else if (cardNumber === 5) {
      navigate(`/plataforma/Diagnostico-Geral-Saude`);
    } else if (cardNumber === 6) {
      navigate(`/plataforma/Diagnostico-Enfermagem`);
    } else if (cardNumber === 7) {
      navigate(`/plataforma/Diagnostico-Fisioterapeuta`);
    } else if (cardNumber === 8) {
      navigate(`/plataforma/Diagnostico-Cirurgiao-Dentista`);
    } else if (cardNumber === 9) {
      navigate(`/plataforma/Diagnostico-Psiquiatra`);
    } else if (cardNumber === 10) {
      navigate(`/plataforma/Diagnostico-Cardiologista`);
    } else if (cardNumber === 11) {
      navigate(`/plataforma/Diagnostico-Ortopedista`);
    } else if (cardNumber === 12) {
      navigate(`/plataforma/Diagnostico-Oftomologista`);
    } else if (cardNumber === 13) {
      navigate(`/plataforma/Diagnostico-Clinico-Geral`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderDiagnostico />
      <MainContent>
        <WelcomeText>Diagnóstico</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form30;
