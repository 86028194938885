import React, { useEffect, useState } from "react";
import supabase from "../../Supabase/supabase";
import { useSession } from "../../Routers/AppRouter";

const ComponenteComPermissao = ({ permissaoNecessaria, children }) => {
  const [permissaoUsuario, setPermissaoUsuario] = useState(null);
  const session = useSession();

  useEffect(() => {
    const fetchPermissao = async () => {
      try {
        if (!session) {
          return;
        }

        const { data, error } = await supabase
          .from("profiles")
          .select(`permission_level`)
          .eq("id", session.user.id);

        if (data && data.length > 0) {
          setPermissaoUsuario(data[0].permission_level);
        }
      } catch (error) {
        console.error("Erro ao buscar permissão:", error);
      }
    };

    fetchPermissao();
  }, [session]);

  if (permissaoUsuario === permissaoNecessaria) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default ComponenteComPermissao;
