import React, { useState, useEffect } from "react";
import supabase from "../../../Supabase/supabase";
import HeaderGeral from "../../../Components/headerGeral";

import { useNavigate } from "react-router-dom";
import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  RightImage,
  FormInput,
  SummaryInput,
  StyledButton,
  TextDiagnotico,
  Button,
  Table,
  TableHeader,
  TableCell,
  DivInputs,
  DivImage,
} from "./styles";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form32 = () => {
  const [summary, setSummary] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [nome, setNome] = useState("");
  const [register, setRegister] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchID, setSearchID] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const data = searchResults;

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setSearchID(cpf);
    setId(cpf);
    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const fetchDataByID = async () => {
    try {
      setLoading(true);
      const { data: Form2Data, error: Form2Error } = await supabase
        .from("sociodemograficoMilitar")
        .select("name, email")
        .eq("id", String(searchID).trim());
      if (Form2Error) {
      }
      const { data: Form200Data, error: Form200Error } = await supabase
        .from("sociodemograficoEscolar")
        .select("name, email")
        .eq("id", String(searchID).trim());
      if (Form200Error) {
      }
      const { data: Form2000Data, error: Form2000Error } = await supabase
        .from("sociodemograficoCivil")
        .select("name, email")
        .eq("id", String(searchID).trim());
      if (Form2000Error) {
      }
      const { data: Form201Data, error: Form201Error } = await supabase
        .from("sociodemograficoSefaz")
        .select("name, email")
        .eq("id", String(searchID).trim());
      if (Form201Error) {
      }
      const { data: Form3Data, error: Form3Error } = await supabase
        .from("caracHabVida")
        .select("q1, q2, q3, q4, q5, q6, q7")
        .eq("id", searchID);
      if (Form3Error) throw Form3Error;

      const { data: Form21Data, error: Form21Error } = await supabase
        .from("habitosVida")
        .select(
          "physicalDomainClassification, psychologicalDomainClassification,  socialRelationsDomainClassification, environmentDomainClassification"
        )
        .eq("id", searchID);
      if (Form21Error) throw Form21Error;

      const { data: Form12Data, error: Form12Error } = await supabase
        .from("estresseTrabalho")
        .select(
          "nivelDeEstresseMediaGeral, nivelDeEstresseRoleWorkEnvironment, nivelDeEstresseAutonomyControl, nivelDeEstresseRelationshipWithSupervisor, nivelDeEstresseRelacionamentosInterpessoais, nivelDeEstresseCrescimentoValoração"
        )
        .eq("id", searchID);
      if (Form12Error) throw Form12Error;

      const { data: Form13Data, error: Form13Error } = await supabase
        .from("avaliEsgoPro")
        .select("burnoutClassification")
        .eq("id", searchID);
      if (Form13Error) throw Form13Error;

      const { data: Form14Data, error: Form14Error } = await supabase
        .from("avaliFatorBurn")
        .select(" resultado1,  resultado2,  resultado3")
        .eq("id", searchID);
      if (Form14Error) throw Form14Error;

      const { data: Form6Data, error: Form6Error } = await supabase
        .from("nivelAtiviFisica")
        .select("classification")
        .eq("id", searchID);
      if (Form6Error) throw Form6Error;

      const { data: Form25Data, error: Form25Error } = await supabase
        .from("saudePacientePHQ9")
        .select("result")
        .eq("id", searchID);
      if (Form25Error) throw Form25Error;

      const { data: Form24Data, error: Form24Error } = await supabase
        .from("escalaDepreAnsiEstresse")
        .select(
          "depressionClassification, anxietyClassification, stressClassification"
        )
        .eq("id", searchID);
      if (Form24Error) throw Form24Error;

      const { data: Form16Data, error: Form16Error } = await supabase
        .from("imc")
        .select("bmiClassification")
        .eq("id", searchID);
      if (Form16Error) throw Form16Error;

      const { data: Form17Data, error: Form17Error } = await supabase
        .from("irqc")
        .select("riscoCircunferenciaCintura")
        .eq("id", searchID);
      if (Form17Error) throw Form17Error;

      const { data: Form18Data, error: Form18Error } = await supabase
        .from("testePercGordu")
        .select("classificacao")
        .eq("id", searchID);
      if (Form18Error) throw Form18Error;

      const { data: Form19Data, error: Form19Error } = await supabase
        .from("exameSindroMeta")
        .select("message")
        .eq("id", searchID);
      if (Form19Error) throw Form19Error;

      const { data: Form20Data, error: Form20Error } = await supabase
        .from("examesBioComp")
        .select(
          "diagnósticoGlicemia,diagnósticoLDL, diagnósticoVLDL, diagnósticoFerritina, diagnósticoCreatinina, diagnósticoUreia, diagnósticoALT,diagnósticoAST, diagnósticoColesterolTotal"
        )
        .eq("id", searchID);
      if (Form20Error) throw Form20Error;

      const { data: Form22Data, error: Form22Error } = await supabase
        .from("automedicação")
        .select("ingeriuMedicamentos,todosPrescritos")
        .eq("id", searchID);
      if (Form22Error) throw Form22Error;

      const { data: Form11Data, error: Form11Error } = await supabase
        .from("diagnosticoGeralSaude")
        .select("diagnosis,summary")
        .eq("id", searchID);
      if (Form11Error) throw Form11Error;

      const { data: Form1000Data, error: Form1000Error } = await supabase
        .from("diagnosticoMedico")
        .select("diagnosis,summary")
        .eq("id", searchID);
      if (Form1000Error) throw Form1000Error;

      const { data: Form1001Data, error: Form1001Error } = await supabase
        .from("diagnosticoNutricionista")
        .select("diagnosis,summary")
        .eq("id", searchID);
      if (Form1001Error) throw Form1001Error;

      const { data: Form1002Data, error: Form1002Error } = await supabase
        .from("diagnosticoPsicologo")
        .select("diagnosis,summary")
        .eq("id", searchID);
      if (Form1002Error) throw Form1002Error;

      const { data: Form1003Data, error: Form1003Error } = await supabase
        .from("diagnosticoEdFisica")
        .select("diagnosis,summary")
        .eq("id", searchID);
      if (Form1003Error) throw Form1003Error;

      const { data: FormHemogramaData, error: FormHemogramaError } =
        await supabase
          .from("hemograma")
          .select(
            "diagnósticoHCM, diagnósticoHemoglobina, diagnósticoCHCM, diagnósticoEritrócitos, diagnósticoVCM, diagnósticoHematócrito, diagnósticoContagem, diagnósticoAmplitude, diagnósticoNeutrófilos, diagnósticoLinfócitos, diagnósticoMonócitos, diagnósticoEosinófilos, diagnósticoBasófilos, diagnósticoPlaquetas"
          )
          .eq("id", searchID);
      if (FormHemogramaError) throw FormHemogramaError;

      const { data: FormAnsiedadeBeckData, error: FormAnsiedadeBeckError } =
        await supabase
          .from("ansiedadeBeck")
          .select("burnoutClassification")
          .eq("id", searchID);
      if (FormAnsiedadeBeckError) throw FormAnsiedadeBeckError;

      const { data: FormDepressaoBeckData, error: FormDepressaoBeckError } =
        await supabase
          .from("depressaoBeck")
          .select("burnoutClassification")
          .eq("id", searchID);
      if (FormDepressaoBeckError) throw FormDepressaoBeckError;

      const { data: FormSofrimentoData, error: FormSofrimentoError } =
        await supabase
          .from("sofrimentoMental")
          .select("burnoutClassification")
          .eq("id", searchID);
      if (FormSofrimentoError) throw FormSofrimentoError;

      const consolidatedData = {
        Form2: Form2Data,
        Form200: Form200Data,
        Form2000: Form2000Data,
        Form201: Form201Data,
        Form3: Form3Data,
        Form21: Form21Data,
        Form12: Form12Data,
        Form13: Form13Data,
        Form14: Form14Data,
        Form6: Form6Data,
        Form25: Form25Data,
        Form24: Form24Data,
        Form16: Form16Data,
        Form17: Form17Data,
        Form18: Form18Data,
        Form19: Form19Data,
        Form20: Form20Data,
        Form22: Form22Data,
        Form11: Form11Data,
        Form1001: Form1001Data,
        Form1000: Form1000Data,
        Form1002: Form1002Data,
        Form1003: Form1003Data,
        FormHemograma: FormHemogramaData,
        FormAnsiedadeBeck: FormAnsiedadeBeckData,
        FormDepressaoBeck: FormDepressaoBeckData,
        FormSofrimento: FormSofrimentoData,
      };

      setSearchResults(consolidatedData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      summary,
      diagnosis,
      nome,
      register,
    };

    const { error } = await supabase
      .from("diagnosticoNutricionista")
      .insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );

      setFormSubmitted(true);
    }
  };

  useEffect(() => {}, [searchResults]);

  const downloadPDF = (blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "relatorio.pdf";

    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Escolher-Diagnostico");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <TextDiagnotico>Diagnostico Nutricionista</TextDiagnotico>
        <FormGroup>
          <FormLabel>Buscar por CPF</FormLabel>
          <FormInput
            type="text"
            value={searchID}
            onChange={handleCPFChange}
            placeholder="Digite o CPF"
            customWidth="22.87vw"
          />

          {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          {loading && <p>Carregando...</p>}
        </FormGroup>
        <StyledButton
          onClick={() => {
            fetchDataByID();
          }}
          disabled={!isValid || loading}
        >
          Buscar
        </StyledButton>
        {searchResults.Form2 && searchResults.Form2.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">DADOS BÁSICOS</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <TableCell>Nome: </TableCell>
                <TableCell>{searchResults.Form2[0].name}</TableCell>{" "}
              </tr>
              <tr>
                {" "}
                <TableCell>Email: </TableCell>
                <TableCell>{searchResults.Form2[0].email}</TableCell>{" "}
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form200 && searchResults.Form200.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">DADOS BÁSICOS</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <TableCell>Nome: </TableCell>
                <TableCell>{searchResults.Form200[0].name}</TableCell>{" "}
              </tr>
              <tr>
                {" "}
                <TableCell>Email: </TableCell>
                <TableCell>{searchResults.Form200[0].email}</TableCell>{" "}
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form2000 && searchResults.Form2000.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">DADOS BÁSICOS</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <TableCell>Nome: </TableCell>
                <TableCell>{searchResults.Form2000[0].name}</TableCell>{" "}
              </tr>
              <tr>
                {" "}
                <TableCell>Email: </TableCell>
                <TableCell>{searchResults.Form2000[0].email}</TableCell>{" "}
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form201 && searchResults.Form201.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">DADOS BÁSICOS</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <TableCell>Nome: </TableCell>
                <TableCell>{searchResults.Form201[0].name}</TableCell>{" "}
              </tr>
              <tr>
                {" "}
                <TableCell>Email: </TableCell>
                <TableCell>{searchResults.Form201[0].email}</TableCell>{" "}
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form3 &&
          searchResults.Form3.map((form3Data, index) => (
            <Table key={index}>
              <thead>
                <tr>
                  <TableHeader colspan="2">
                    CARACTERIZAÇÃO DOS HÁBITOS DE VIDA
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>Fumante:</TableCell>{" "}
                  <TableCell>{form3Data.q1}</TableCell>
                </tr>
                <tr>
                  <TableCell>Consumo de álcool:</TableCell>{" "}
                  <TableCell>{form3Data.q2}</TableCell>
                </tr>
                <tr>
                  <TableCell>Alimentação saudável:</TableCell>{" "}
                  <TableCell>{form3Data.q3}</TableCell>
                </tr>
                <tr>
                  <TableCell>Dificuldades para dormir:</TableCell>{" "}
                  <TableCell>{form3Data.q4}</TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Meio de transporte utilizado para ir ao trabalho:
                  </TableCell>{" "}
                  <TableCell>{form3Data.q5}</TableCell>
                </tr>
                <tr>
                  <TableCell>Possui moradia própria:</TableCell>{" "}
                  <TableCell>{form3Data.q6}</TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Sua residência fica em qual zona da cidade:
                  </TableCell>{" "}
                  <TableCell>{form3Data.q7}</TableCell>
                </tr>
              </tbody>
            </Table>
          ))}
        {searchResults.Form21 && searchResults.Form21.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  AVALIAÇÃO DA QUALIDADE DE VIDA RELACIONADA A SAÚDE
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Domínio Físico:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form21[0].physicalDomainClassification}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Domínio Psicológico:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form21[0].psychologicalDomainClassification}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Relações Sociais:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form21[0].socialRelationsDomainClassification}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Meio Ambiente:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form21[0].environmentDomainClassification}
                </TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form12 && searchResults.Form12.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  ESCALA DE ESTRESSE NO TRABALHO
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Média Geral:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form12[0].nivelDeEstresseMediaGeral}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Papais e Ambiente de Trabalho:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form12[0].nivelDeEstresseRoleWorkEnvironment}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Autonomia e Controle:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form12[0].nivelDeEstresseAutonomyControl}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Relacionamento com o Chefe:</TableCell>{" "}
                <TableCell>
                  {
                    searchResults.Form12[0]
                      .nivelDeEstresseRelationshipWithSupervisor
                  }
                </TableCell>
              </tr>
              <tr>
                <TableCell>Relacionamentos Interpessoais:</TableCell>{" "}
                <TableCell>
                  {
                    searchResults.Form12[0]
                      .nivelDeEstresseRelacionamentosInterpessoais
                  }
                </TableCell>
              </tr>
              <tr>
                <TableCell>Crescismento e Valorização:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form12[0].nivelDeEstresseCrescimentoValoração}
                </TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form13 && searchResults.Form13.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Classificação Burnout:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form13[0].burnoutClassification}
                </TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form14 && searchResults.Form14.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  VARIAVEIS POR FATOR DE BURNOUT
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Exaustão Emocional:</TableCell>{" "}
                <TableCell>{searchResults.Form14[0].resultado1}</TableCell>
              </tr>
              <tr>
                <TableCell>Despersonalização:</TableCell>{" "}
                <TableCell>{searchResults.Form14[0].resultado2}</TableCell>
              </tr>
              <tr>
                <TableCell>Despersonalização:</TableCell>{" "}
                <TableCell>{searchResults.Form14[0].resultado3}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form6 && searchResults.Form6.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">NÍVEL DE ATIVIDADE FÍSICA</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Classificação:</TableCell>{" "}
                <TableCell>{searchResults.Form6[0].classification}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form25 && searchResults.Form25.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">ESCALA DE DEPRESSÃO</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Resultado:</TableCell>{" "}
                <TableCell>{searchResults.Form25[0].result}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form24 && searchResults.Form24.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  ESCALA DE DEPRESSÃO, ANSIEDADE E ESTRESSE
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Classificação Depressão:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form24[0].depressionClassification}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Classificação Ansiedade:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form24[0].anxietyClassification}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Classificação Estresse:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form24[0].stressClassification}
                </TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form16 && searchResults.Form16.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">ÍNDICE DE MASSA CORPORAL</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>IMC:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form16[0].bmiClassification}
                </TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form17 && searchResults.Form17.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  ÍNDICE RELAÇÃO CINTURA/QUADRIL
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>IRCQ:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form17[0].riscoCircunferenciaCintura}
                </TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form18 && searchResults.Form18.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">ÍNDICE DE MASSA CORPORAL</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Percentual de Gordura:</TableCell>{" "}
                <TableCell>{searchResults.Form18[0].classificacao}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form19 && searchResults.Form19.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">EXAME SÍNDROME METABÓLICA</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Síndrome Metabólca:</TableCell>{" "}
                <TableCell>{searchResults.Form19[0].message}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form22 && searchResults.Form22.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">AUTOMEDICAÇÃO</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Ingeriu medicamentos no último ano?</TableCell>{" "}
                <TableCell>
                  {searchResults.Form22[0].ingeriuMedicamentos}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Todos prescritos pelo médico?</TableCell>{" "}
                <TableCell>{searchResults.Form22[0].todosPrescritos}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form20 && searchResults.Form20.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">EXAMES LABORATORIAIS</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Glicemia:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form20[0].diagnósticoGlicemia}
                </TableCell>
              </tr>
              <tr>
                <TableCell>LDL:</TableCell>{" "}
                <TableCell>{searchResults.Form20[0].diagnósticoLDL}</TableCell>
              </tr>
              <tr>
                <TableCell>VLDL:</TableCell>{" "}
                <TableCell>{searchResults.Form20[0].diagnósticoVLDL}</TableCell>
              </tr>
              <tr>
                <TableCell>Ferritina:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form20[0].diagnósticoFerritina}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Creatinina:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form20[0].diagnósticoCreatinina}
                </TableCell>
              </tr>
              <tr>
                <TableCell>Ureia:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form20[0].diagnósticoUreia}
                </TableCell>
              </tr>
              <tr>
                <TableCell>ALT:</TableCell>{" "}
                <TableCell>{searchResults.Form20[0].diagnósticoALT}</TableCell>
              </tr>
              <tr>
                <TableCell>AST:</TableCell>{" "}
                <TableCell>{searchResults.Form20[0].diagnósticoAST}</TableCell>
              </tr>
              <tr>
                <TableCell>Colesterol Total:</TableCell>{" "}
                <TableCell>
                  {searchResults.Form20[0].diagnósticoColesterolTotal}
                </TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.FormHemograma &&
          searchResults.FormHemograma.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <TableHeader colspan="2">RESULTADO HEMOGRAMA</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>Hemoglobina:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoHemoglobina}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Hematócrito:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoHematócrito}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Volume celular (ou corpuscular) médico (VCM):
                  </TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoVCM}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Hemoglobina celular (ou corpuscular) média (HCM):
                  </TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoHCM}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Concentração de hemoglobina celular (ou corpuscular) média
                    (CHCM):
                  </TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoCHCM}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Contagem de glóbulos vermelhos (eritrócitos):
                  </TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoEritrócitos}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>
                    Amplitude de distribuição de eritrócitos:
                  </TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoAmplitude}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de glóbulos brancos:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoContagem}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de Neutrófilos segmentados:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoNeutrófilos}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de Linfócitos:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoLinfócitos}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de Monócitos:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoMonócitos}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de Eosinófilos:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoEosinófilos}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de Basófilos:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoBasófilos}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Contagem de Plaquetas:</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormHemograma[0].diagnósticoPlaquetas}
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          )}
        {searchResults.FormAnsiedadeBeck &&
          searchResults.FormAnsiedadeBeck.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <TableHeader colspan="2">ANSIEDADE - BECK</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>Classificação :</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormAnsiedadeBeck[0].burnoutClassification}
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          )}
        {searchResults.FormDepressaoBeck &&
          searchResults.FormDepressaoBeck.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <TableHeader colspan="2">DEPRESSÃO - BECK</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>Classificação :</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormDepressaoBeck[0].burnoutClassification}
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          )}
        {searchResults.FormSofrimento &&
          searchResults.FormSofrimento.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <TableHeader colspan="2">SOFRIMENTO MENTAL</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>Classificação :</TableCell>{" "}
                  <TableCell>
                    {searchResults.FormSofrimento[0].burnoutClassification}
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          )}
        {searchResults.Form1000 && searchResults.Form1000.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">DIAGNONÓSTICO MÉDICO</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Diagnóstico:</TableCell>{" "}
                <TableCell>{searchResults.Form1000[0].diagnosis}</TableCell>
              </tr>
              <tr>
                <TableCell>Intervenção:</TableCell>{" "}
                <TableCell>{searchResults.Form1000[0].summary}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form1001 && searchResults.Form1001.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  DIAGNONÓSTICO NUTRICIONISTA
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Diagnóstico:</TableCell>{" "}
                <TableCell>{searchResults.Form1001[0].diagnosis}</TableCell>
              </tr>
              <tr>
                <TableCell>Intervenção:</TableCell>{" "}
                <TableCell>{searchResults.Form1001[0].summary}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form1002 && searchResults.Form1002.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">DIAGNONÓSTICO PSICÓLOGO</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Diagnóstico:</TableCell>{" "}
                <TableCell>{searchResults.Form1002[0].diagnosis}</TableCell>
              </tr>
              <tr>
                <TableCell>Intervenção:</TableCell>{" "}
                <TableCell>{searchResults.Form1002[0].summary}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form1003 && searchResults.Form1003.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  DIAGNONÓSTICO PROFISSIONAL ED.FÍSICA
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Diagnóstico:</TableCell>{" "}
                <TableCell>{searchResults.Form1003[0].diagnosis}</TableCell>
              </tr>
              <tr>
                <TableCell>Intervenção:</TableCell>{" "}
                <TableCell>{searchResults.Form1003[0].summary}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        {searchResults.Form11 && searchResults.Form11.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TableHeader colspan="2">
                  DIAGNONÓSTICO GERAL DA SAÚDE
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Diagnóstico:</TableCell>{" "}
                <TableCell>{searchResults.Form11[0].diagnosis}</TableCell>
              </tr>
              <tr>
                <TableCell>Intervenção:</TableCell>{" "}
                <TableCell>{searchResults.Form11[0].summary}</TableCell>
              </tr>
            </tbody>
          </Table>
        )}
        <form onSubmit={handleSubmit}>
          <DivInputs>
            <FormGroup>
              <FormLabel>Nome completo</FormLabel>
              <FormInput
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                customWidth="22.87vw"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Número do registro profissional</FormLabel>
              <FormInput
                value={register}
                onChange={(e) => setRegister(e.target.value)}
                customWidth="22.87vw"
              />
            </FormGroup>
          </DivInputs>
          <FormGroup>
            <FormLabel>Diagnóstico</FormLabel>
            <SummaryInput
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              width="100%"
              height="300px"
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Intervenção</FormLabel>
            <SummaryInput
              value={diagnosis}
              onChange={(e) => setDiagnosis(e.target.value)}
              width="100%"
              height="200px"
            />
          </FormGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>

            <button
              type="submit"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                borderRadius: 5,
                border: "none",
                width: "9.12vw",
                height: "3.89vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span>Enviar</span>
            </button>
          </div>
        </form>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form32;
