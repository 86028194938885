import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";

import image47 from "../../../Images/image47.png";
import image48 from "../../../Images/image48.png";
import image49 from "../../../Images/image49.png";
import image50 from "../../../Images/image50.png";
import image51 from "../../../Images/image51.png";
import HeaderResultados from "../../../Components/headerResultados";

const images = [image47, image48, image49, image50, image51];
const cardTitles = [
  "POLÍCIA MILITAR",
  " POLÍCIA CIVIL",
  "CORPO DE BOMBEIROS",
  "POLÍCIA PENAL",
  "GUARDA MUNICIPAL",
];

const Form150 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Resultado-Policia-Militar`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Resultado-Policia-Civil`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Resultado-Corpo-De-Bombeiros`);
    } else if (cardNumber === 4) {
      navigate(`/plataforma/Resultado-Policia-Penal`);
    } else if (cardNumber === 5) {
      navigate(`/plataforma/Resultado-Guarda-Municipal`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderResultados />
      <MainContent>
        <WelcomeText>Resultados &#8594; militar</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form150;
