import React from "react";
import { useNavigate } from "react-router-dom";

import image1 from "../../Images/image1.png";
import image2 from "../../Images/image2.png";
import image3 from "../../Images/image3.png";
import image4 from "../../Images/image4.jpg";
import image5 from "../../Images/image5.jpg";
import image6 from "../../Images/image6.jpeg";
import image7 from "../../Images/image7.png";
import image8 from "../../Images/image8.png";
import image9 from "../../Images/image9.png";
import image10 from "../../Images/image10.png";
import image24 from "../../Images/image24.png";
import image43 from "../../Images/image43.png";
import image54 from "../../Images/image54.png";
import {
  Avatar,
  Card,
  CardText,
  ContentTextCard,
  MainContent,
  TextAcess,
  CardContainer,
  WelcomeText,
  CardImage,
} from "./styles";

const MenuFull = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image24,
    image43,
    image6,
    image7,
    image8,
    image9,
    image10,
    image54,
  ];

  const cardTitles = [
    "QUESTIONÁRIO SOCIODEMOGRÁFICO",
    "CARACTERIZAÇÃO DOS HÁBITOS DE VIDA",
    "SAÚDE E QUALIDADE DE VIDA",
    "ESTRESSE LABORAL",
    "NÍVEL DE ATIVIDADE FÍSICA",
    "ANSIEDADE E DEPRESSÃO",
    "SOFRIMENTO MENTAL",
    "AVALIAÇÃO ANTROPOMÉTRICA",
    "EXAMES BIOQUÍMOCOS",
    "RESULTADOS",
    "PAPER",
    "DIAGNONÓSTICO E INTERVENÇÃO",
    "TELE CONSULTA",
  ];
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Sociodemografico`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Caracterização-dos-hábitos-de-vida`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Saude-e-qualidade-de-vida`);
    } else if (cardNumber === 4) {
      navigate(`/plataforma/Estresse-laboral`);
    } else if (cardNumber === 5) {
      navigate(`/plataforma/Nivel-atividade-física`);
    } else if (cardNumber === 6) {
      navigate(`/plataforma/Ansiedade-depressão`);
    } else if (cardNumber === 7) {
      navigate(`/plataforma/Sofrimento-Mental`);
    } else if (cardNumber === 8) {
      navigate(`/plataforma/Avaliação-antropométrica`);
    } else if (cardNumber === 9) {
      navigate(`/plataforma/Exames-bioquimicos`);
    } else if (cardNumber === 10) {
      navigate(`/plataforma/Resultados`);
    } else if (cardNumber === 11) {
      navigate(`/plataforma/Paper`);
    } else if (cardNumber === 12) {
      navigate(`/plataforma/Diagnostico-intervenção`);
    } else if (cardNumber === 13) {
      navigate(`/plataforma/Escolher-Tele-Consulta`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
        <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <MainContent>
      <WelcomeText>Seja bem vindo ao Sias.</WelcomeText>
      <CardContainer>{renderCards()}</CardContainer>
    </MainContent>
  );
};

export default MenuFull;
