import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../../Supabase/supabase";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  FormRadio,
  FormInput,
  FormErrorMessage,
  Button1,
  ResultContainer,
  ResultMessage,
  Button,
  DivInputs,
  FormHeader,
} from "../../../Components/all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form19 = () => {
  const [sexoExames, setSexoExames] = useState("");
  const [idadeExames, setIdadeExames] = useState("");
  const [colesterolHDL, setColesterolHDL] = useState("");
  const [pressaoArterial, setPressaoArterial] = useState("");
  const [glicemia, setGlicemia] = useState("");
  const [circunferenciaCintura, setCircunferenciaCintura] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [hasAbdominalObesity, setHasAbdominalObesity] = useState(false);
  const [hasLowHDL, setHasLowHDL] = useState(false);
  const [hasHighTriglycerides, setHasHighTriglycerides] = useState(false);
  const [hasHighBloodPressure, setHasHighBloodPressure] = useState(false);
  const [hasHighGlucose, setHasHighGlucose] = useState(false);
  const [hasInsulinResistance, setHasInsulinResistance] = useState(false);

  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, ""); // Mantém apenas números
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleGenerateResult = () => {
    const abdominalObesity =
      (sexoExames === "Masculino" && Number(circunferenciaCintura) > 102) ||
      (sexoExames === "Feminino" && Number(circunferenciaCintura) > 88);

    const lowHDL =
      (sexoExames === "Masculino" && Number(colesterolHDL) < 40) ||
      (sexoExames === "Feminino" && Number(colesterolHDL) < 50);

    const highTriglycerides = Number(glicemia) >= 150;

    const pressaoArterialParts = pressaoArterial.split("/");
    const highBloodPressure =
      pressaoArterial &&
      pressaoArterial.includes("/") &&
      (Number(pressaoArterialParts[0]) >= 135 ||
        Number(pressaoArterialParts[1]) >= 85);

    const highGlucose = Number(glicemia) >= 110;

    const insulinResistance =
      (abdominalObesity ? 1 : 0) +
        (lowHDL ? 1 : 0) +
        (highTriglycerides ? 1 : 0) +
        (highBloodPressure ? 1 : 0) +
        (highGlucose ? 1 : 0) >=
      3;

    setShowResult(true);
    setHasAbdominalObesity(abdominalObesity);
    setHasLowHDL(lowHDL);
    setHasHighTriglycerides(highTriglycerides);
    setHasHighBloodPressure(highBloodPressure);
    setHasHighGlucose(highGlucose);
    setHasInsulinResistance(insulinResistance);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const resistanceMessage = hasInsulinResistance
      ? "Você possui resistência insulínica."
      : "Você não possui resistência insulínica.";

    const data = {
      id,
      message: resistanceMessage,
    };

    const { error } = await supabase.from("exameSindroMeta").insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };
  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Exames-bioquimicos");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormHeader>
              <h2> EXAME SÍNDROME METABÓLICA</h2>
            </FormHeader>

            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="22.87vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormRadioGroup>
              {["Masculino", "Feminino"].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="sexoExames"
                    value={value}
                    checked={sexoExames === value}
                    onChange={(e) => setSexoExames(e.target.value)}
                  />
                  <span>{value}</span>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Idade:</FormLabel>
            <FormRadioGroup>
              {[
                "18 a 29 anos",
                "30 a 40 anos",
                "41 a 51 anos",
                "52 a 62 anos",
                "Acima de 62 anos",
              ].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="idadeExames"
                    value={value}
                    checked={idadeExames === value}
                    onChange={(e) => setIdadeExames(e.target.value)}
                  />
                  <span>{value}</span>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <DivInputs>
            <FormGroup>
              <FormLabel>Circunferência da Cintura (cm):</FormLabel>
              <FormInput
                type="text"
                placeholder="Circunferência da Cintura"
                value={circunferenciaCintura}
                onChange={(e) => setCircunferenciaCintura(e.target.value)}
              />
              {hasAbdominalObesity && (
                <FormErrorMessage>
                  Grande quantidade de gordura abdominal. Risco de resistência
                  insulínica.
                </FormErrorMessage>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>Colesterol HDL:</FormLabel>
              <FormInput
                type="text"
                placeholder="Colesterol HDL"
                value={colesterolHDL}
                onChange={(e) => setColesterolHDL(e.target.value)}
              />
              {hasLowHDL && (
                <FormErrorMessage>
                  Valor baixo de HDL. Risco de resistência insulínica.
                </FormErrorMessage>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>Pressão Arterial</FormLabel>

              <FormInput
                type="text"
                placeholder="Pressão Arterial ex: (120/80)"
                value={pressaoArterial}
                onChange={(e) => setPressaoArterial(e.target.value)}
                customWidth="12.87vw"
              />
              {hasHighBloodPressure && (
                <FormErrorMessage>
                  Pressão arterial alta. Risco de resistência insulínica.
                </FormErrorMessage>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>Glicemia:</FormLabel>
              <FormInput
                type="text"
                placeholder="Glicemia"
                value={glicemia}
                onChange={(e) => setGlicemia(e.target.value)}
              />
              {hasHighGlucose && (
                <FormErrorMessage>
                  Glicemia alta. Risco de resistência insulínica.
                </FormErrorMessage>
              )}
            </FormGroup>
          </DivInputs>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>

            <Button1 onClick={handleGenerateResult}>Gerar Resultado</Button1>
          </div>
          {showResult && (
            <ResultContainer>
              <ResultMessage>
                {hasInsulinResistance
                  ? "Você possui resistência insulínica."
                  : "Você não possui resistência insulínica."}
              </ResultMessage>
            </ResultContainer>
          )}
        </form>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form19;
