import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import image4 from "../../Images/image4.jpg";
import image24 from "../../Images/image24.png";
import image43 from "../../Images/image43.png";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100vw;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

const MainContent = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 72.91vw;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 12px;
    align-items: center;
    justify-content: center;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 8px 8px 8px rgba(0, 0, 0.2, 0.4);
  border: 1px solid #1f1087;
  cursor: pointer;
  color: #1f1087;
  width: 20.66vw;
  height: 4.63dvh;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: #ffffff;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  width: 55px;
  height: 53px;
`;

const images = [image4, image24, image43];

const cardTitles = [
  "ESTRESSE LABORAL",

  "ANSIEDADE E DEPRESSÃO",
  "SOFRIMENTO MENTAL",
];

const MenuAvaliador2 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Estresse-laboral`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Ansiedade-depressão`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Sofrimento-Mental`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <CardImage src={image} alt={`Card ${index + 1}`} />
        <h5>{cardTitles[index]}</h5>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <MainContent>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default MenuAvaliador2;
