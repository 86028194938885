import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
`;

export const DivImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
`;

export const FormContainer = styled.div`
  width: 95vw;
`;

export const DivInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const FormGroupIntro = styled.div`
  margin-bottom: 20px;
`;

export const FormLabelIntro = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  text-align: justify;
`;

export const FormGroupCpf = styled.div`
  margin-bottom: 20px;
`;

export const FormGroup = styled.div`
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 15vh;
  &:nth-child(even) {
    background-color: #e9e9e9;
  }
`;

export const FormLabel = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  text-align: justify;
  margin-left: 20px;
  min-width: 12vw;
  max-width: 12vw;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FormInput = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const FormSelect = styled.select`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const FormCheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  text-align: justify;
`;

export const FormHeader = styled.div`
  margin-top: 40px;
  text-align: start;
  margin-bottom: 20px;
  color: #1f1087;
`;

export const FormCheckboxLabel = styled.label`
  font-size: 0.7rem;
  margin-bottom: 8px;
`;

export const FormCheckbox = styled.input`
  margin-right: 8px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #b8b8b8;
  color: black;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  &:hover {
    transform: scale(1.09);
  }
`;

export const FormRadio = styled.input`
  font-size: 1.7rem;

  display: flex;
  margin: 0 10px;
`;

export const FormRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8vw;
  font-size: 1rem;
  text-align: center;
`;

export const ResultContainer = styled.div`
  margin-top: 20px;
`;

export const ResultValue = styled.p`
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

export const ResultStatus = styled.p`
  font-size: 1.2rem;
`;
export const FilterButton = styled.button`
  background-color: #2e19bd;
  color: #fff;
  border-radius: 5px;
  border: none;
  min-width: 9.12vw;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;
