import React, { useState, useEffect } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";

import HeaderGeral from "../../../Components/headerGeral";

import {
  Button,
  Container,
  FormInput,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  FilterButton,
  FormHeader,
  FormRadio,
  ResultContainer,
  ResultStatus,
  FormGroupIntro,
  FormLabelIntro,
  DivInfos,
  Infos,
} from "./styles";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form100003 = () => {
  const [showResult, setShowResult] = useState(false);
  const [q1a, setQ1a] = useState("0");
  const [q2a, setQ2a] = useState("0");
  const [q3a, setQ3a] = useState("0");
  const [q4a, setQ4a] = useState("0");
  const [q5a, setQ5a] = useState("0");
  const [q6a, setQ6a] = useState("0");
  const [q7a, setQ7a] = useState("0");
  const [q8a, setQ8a] = useState("0");
  const [q9a, setQ9a] = useState("0");
  const [q10a, setQ10a] = useState("0");
  const [q11a, setQ11a] = useState("0");
  const [q12a, setQ12a] = useState("0");
  const [q13a, setQ13a] = useState("0");
  const [q14a, setQ14a] = useState("0");
  const [q15a, setQ15a] = useState("0");
  const [q16a, setQ16a] = useState("0");
  const [q17a, setQ17a] = useState("0");
  const [q18a, setQ18a] = useState("0");
  const [q19a, setQ19a] = useState("0");
  const [q20a, setQ20a] = useState("0");
  const [q21a, setQ21a] = useState("0");
  const [q22a, setQ22a] = useState("0");
  const [burnoutTotal, setBurnoutTotal] = useState(null);
  const [burnoutClassification, setBurnoutClassification] = useState(" ");
  const [calculated, setCalculated] = useState(false);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  useEffect(() => {
    const answersTo22Questions = [
      q1a,
      q2a,
      q3a,
      q4a,
      q5a,
      q6a,
      q7a,
      q8a,
      q9a,
      q10a,
      q11a,
      q12a,
      q13a,
      q14a,
      q15a,
      q16a,
      q17a,
      q18a,
      q19a,
      q20a,
      q21a,
      q22a,
    ];
    const total = calcularBurnoutTotal(answersTo22Questions);
    const classification = classifyBurnoutLevel(total);
    setBurnoutTotal(total);
    setBurnoutClassification(classification);
    setCalculated(true);
  }, [
    q1a,
    q2a,
    q3a,
    q4a,
    q5a,
    q6a,
    q7a,
    q8a,
    q9a,
    q10a,
    q11a,
    q12a,
    q13a,
    q14a,
    q15a,
    q16a,
    q17a,
    q18a,
    q19a,
    q20a,
    q21a,
    q22a,
  ]);

  const calcularBurnoutTotal = (answers) => {
    const valoresNumericos = answers.map((answer) => parseFloat(answer));

    if (valoresNumericos.some(isNaN)) {
      return null;
    }

    const total = valoresNumericos.reduce((sum, value) => sum + value, 0);
    return total;
  };

  const classifyBurnoutLevel = (total) => {
    if (total >= 0 && total <= 6) {
      return "o indivíduo não está com sofrimento mental";
    } else {
      return "está comprovado sofrimento mental";
    }
  };

  const handleBurnoutCalculation = () => {
    const answersTo22Questions = [
      q1a,
      q2a,
      q3a,
      q4a,
      q5a,
      q6a,
      q7a,
      q8a,
      q9a,
      q10a,
      q11a,
      q12a,
      q13a,
      q14a,
      q15a,
      q16a,
      q17a,
      q18a,
      q19a,
      q20a,
      q21a,
      q22a,
    ];
    const total = calcularBurnoutTotal(answersTo22Questions);

    const classification = classifyBurnoutLevel(total);

    setBurnoutTotal(total);
    setBurnoutClassification(classification);

    setShowResult(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        id,
        burnoutClassification,
      };

      const { data, error } = await supabase
        .from("sofrimentoMental")
        .insert([formData]);

      if (error) {
        toast.error(
          <ErrorToast>
            <strong>Erro!</strong> Erro ao inserir os dados.
          </ErrorToast>
        );
      } else {
        toast.success(
          <SuccessToast>
            <strong>Dados inseridos com Sucesso!</strong>
          </SuccessToast>
        );
      }
    } catch (e) {
      console.error("Erro inesperado:", e);
    }
  };

  const handleProfileClick = () => {
    navigate("/plataforma/Sofrimento-mental");
  };

  const getLabelForValue = (value) => {
    switch (value) {
      case 0:
        return "Não";
      case 1:
        return "Sim";
    }
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>SOFRIMENTO MENTAL</h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <FormGroupIntro>
            <FormLabelIntro>
              Teste que avalia o sofrimento mental. Por favor, leia estas
              instruções antes de preencher as questões abaixo. É muito
              importante que todos que estão preenchendo o questionário sigam as
              mesmas instruções.
              <br />
              <br />
              Instruções
              <br />
              <br />
              Estas questões são relacionadas a certas dores e problemas que
              podem ter lhe incomodado nos últimos 30 dias. Se você acha que a
              questão se aplica a você e você teve o problema descrito nos
              últimos 30 dias responda SIM. Por outro lado, se a questão não se
              aplica a você e você não teve o problema nos últimos 30 dias,
              responda NÃO.
            </FormLabelIntro>
          </FormGroupIntro>

          <FormGroupIntro>
            <FormLabelIntro>Nº de CPF :</FormLabelIntro>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="25vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroupIntro>

          <DivInfos>
            <Infos>SIM</Infos>
            <Infos>NÃO</Infos>
          </DivInfos>

          <FormGroup>
            <FormLabel>Você sente dores de cabeça frequente?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q1a"
                    value={value.toString()}
                    onChange={(e) => setQ1a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem falta de apetite?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q2a"
                    value={value.toString()}
                    onChange={(e) => setQ2a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Dorme mal?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q3a"
                    value={value.toString()}
                    onChange={(e) => setQ3a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Assusta-se com facilidade?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q4a"
                    value={value.toString()}
                    onChange={(e) => setQ4a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem tremores nas mãos?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q5a"
                    value={value.toString()}
                    onChange={(e) => setQ5a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Sente-se nervoso(a), tenso(a) ou preocupado(a)?
            </FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q6a"
                    value={value.toString()}
                    onChange={(e) => setQ6a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem má digestão?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q7a"
                    value={value.toString()}
                    onChange={(e) => setQ7a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem dificuldades de pensar com clareza?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q8a"
                    value={value.toString()}
                    onChange={(e) => setQ8a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem se sentindo triste ultimamente?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q9a"
                    value={value.toString()}
                    onChange={(e) => setQ9a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem chorado mais do que cosutume?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q10a"
                    value={value.toString()}
                    onChange={(e) => setQ10a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Encontra dificuldade para realizar com satisfação suas atividades
              diárias?
            </FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q11a"
                    value={value.toString()}
                    onChange={(e) => setQ11a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem dificuldade para tomar decisões?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q12a"
                    value={value.toString()}
                    onChange={(e) => setQ12a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tem dificuldade no serviço (seu tralho é pensoso, lhe causa
              sofrimento?)
            </FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q13a"
                    value={value.toString()}
                    onChange={(e) => setQ13a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              É incapaz de desempenhar um papel útil na sua vida?
            </FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q14a"
                    value={value.toString()}
                    onChange={(e) => setQ14a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem perdido interesse pelas coisas?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q15a"
                    value={value.toString()}
                    onChange={(e) => setQ15a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Você se sente uma pessoa iútil, sem préstimo?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q16a"
                    value={value.toString()}
                    onChange={(e) => setQ16a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem idéia de como acabar a vida?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q17a"
                    value={value.toString()}
                    onChange={(e) => setQ17a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Sente-se cansado(a) o tempo todo?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q18a"
                    value={value.toString()}
                    onChange={(e) => setQ18a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Você se cansa com facilidade?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q19a"
                    value={value.toString()}
                    onChange={(e) => setQ19a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tem sensações desagradavéis no estomago?</FormLabel>
            <FormRadioGroup>
              {[0, 1].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q20a"
                    value={value.toString()}
                    onChange={(e) => setQ20a(e.target.value)}
                  />
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
              marginTop: 30,
              marginBottom: 40,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>
            <FilterButton onClick={handleBurnoutCalculation}>
              Calcular sofrimento mental
            </FilterButton>
          </div>

          {showResult && (
            <ResultContainer>
              <div>
                <ResultStatus>
                  Classificação: {burnoutClassification}
                </ResultStatus>
              </div>
            </ResultContainer>
          )}
        </form>
      </FormContainer>

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form100003;
