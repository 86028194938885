import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/home";
import Form1 from "../Pages/sias/sociodemograficoCivil";
import Form2 from "../Pages/sias/sociodemograficoMilitar";
import Form3 from "../Pages/sias/caracHabVida";
import Form4 from "../Pages/sias/saudeQualVida";
import Form5 from "../Pages/sias/estresseLaboral";
import Form6 from "../Pages/sias/nivelAtivFisica";
import Form7 from "../Pages/sias/avaliaçãoAntro";
import Form8 from "../Pages/sias/examesBio";
import Form9 from "../Pages/sias/resultados";
import Form10 from "../Pages/sias/paper";
import Form11 from "../Pages/sias/diagnosticoGeralSaude";
import Form12 from "../Pages/sias/estresseTrabalho";
import Form13 from "../Pages/sias/avaliEsgoPro";
import Form14 from "../Pages/sias/varivaisFatorBurn";
import Form15 from "../Pages/sias/sociodemografico";
import Form16 from "../Pages/sias/imc";
import Form17 from "../Pages/sias/irqc";
import Form18 from "../Pages/sias/testePercGordu";
import Form19 from "../Pages/sias/examesSindroMeta";
import Form20 from "../Pages/sias/examesLabo";
import Form21 from "../Pages/sias/habitosVida";
import Form22 from "../Pages/sias/automedicação";
import Form23 from "../Pages/sias/ansiedadeDepre";
import Form24 from "../Pages/sias/escalaAnsiDepreEstresse";
import Form25 from "../Pages/sias/saudePaciPHQ9";
import Form26 from "../Pages/sias/sociodemograficoEscolar";
import Form27 from "../Pages/sias/diagnosticoIntervenção";
import Form28 from "../Pages/sias/envioRelatorios/index";
import Form29 from "../Pages/sias/sociodemograficoSefaz";
import Form30 from "../Pages/sias/escolherDiagnostico/index";
import FormProfile from "../Pages/sias/perfil";
import Form31 from "../Pages/sias/diagnosticoMedico";
import Form32 from "../Pages/sias/diagnosticoNutricionista";
import Form33 from "../Pages/sias/diagnosticoPsicologo";
import Form34 from "../Pages/sias/diagnosticoProEdFisica";
import Form35 from "../Pages/sias/resultadoCivil";
import Form36 from "../Pages/sias/resultadoPoliciaCivil";
import Form37 from "../Pages/sias/resultadoEscolar";
import Form38 from "../Pages/sias/resultadoSefaz";
import Form39 from "../Pages/sias/examesBioComp";
import Form40 from "../Pages/sias/examesBioParc";
import Form41 from "../Pages/sias/hemograma";
import EscolherSias from "../Pages/sias/escolherSias";

import Form10000 from "../Pages/sias/sofrimentoMental";
import Form100001 from "../Pages/sias/ansiedadeBeck";
import Form100002 from "../Pages/sias/depressaoBeck";
import Form100003 from "../Pages/sias/questSofrimentoMental";
import Form150 from "../Pages/sias/escolherMilitar";
import Form151 from "../Pages/sias/resultadoPoliciaMilitar";
import Form152 from "../Pages/sias/resultadoBombeiros";
import Form153 from "../Pages/sias/resultadoPoliciaPenal";
import Form154 from "../Pages/sias/resultadoGuardaMunicipal";
import Register from "../Pages/registro/Register";
import EscreverPaper from "../Pages/sias/escreverPaper";
import LerPaper from "../Pages/sias/lerPaper";
import VideoConferece from "../Pages/sias/videoConferece";
import FormDiagnosticoEnfermagem from "../Pages/sias/diagnosticoEnfermagem";
import FormDiagnosticoFisioterapeuta from "../Pages/sias/diagnosticoFisioterapeuta";
import FormDiagnosticoCirurgiaoDentista from "../Pages/sias/diagnosticoCirurgiaoDentista";
import FormDiagnosticoPsiquiatra from "../Pages/sias/diagnosticoPsiquiatra";
import FormDiagnosticoCardiologista from "../Pages/sias/diagnosticoCardiologista";
import FormDiagnosticoOrtopedista from "../Pages/sias/diagnosticoOrtopedista";
import FormDiagnosticoOftomologista from "../Pages/sias/diagnosticoOftomologista";
import FormDiagnosticoClinicoGeral from "../Pages/sias/diagnosticoClinicoGeral";
import EscolherTeleConsulta from "../Pages/sias/escolherTeleConsulta";

const FormRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route exact path="/registro" element={<Register />} />

      <Route path="/EscolherSias" element={<EscolherSias />} />

      <Route path="/Sociodemografico" element={<Form15 />} />
      <Route path="/Sociodemografico-Civil" element={<Form1 />} />
      <Route path="/Sociodemografico-Militar" element={<Form2 />} />
      <Route path="/Sociodemografico-Sefaz" element={<Form29 />} />
      <Route path="/Sociodemografico-Escolar" element={<Form26 />} />

      <Route path="/Caracterização-dos-hábitos-de-vida" element={<Form3 />} />

      <Route path="/Saude-e-qualidade-de-vida" element={<Form4 />} />
      <Route path="/Habitos-de-vida" element={<Form21 />} />
      <Route path="/Automedicação" element={<Form22 />} />

      <Route path="/Nivel-atividade-física" element={<Form6 />} />

      <Route path="/Estresse-laboral" element={<Form5 />} />
      <Route path="/Estresse-trabalho" element={<Form12 />} />
      <Route
        path="/Avaliação-do-esgotamento-profissional"
        element={<Form13 />}
      />
      <Route path="/Variaveis-por-fator-burnout" element={<Form14 />} />

      <Route path="/Ansiedade-depressão" element={<Form23 />} />
      <Route path="/Saúde-do-paciente-PHQ9" element={<Form25 />} />
      <Route
        path="/Escala-de-ansiedade-depressão-e-estresse"
        element={<Form24 />}
      />

      <Route path="/Avaliação-antropométrica" element={<Form7 />} />
      <Route path="/IMC" element={<Form16 />} />
      <Route path="/IRQC" element={<Form17 />} />
      <Route path="/Teste-percentual-gordura" element={<Form18 />} />

      <Route path="/Exames-bioquimicos" element={<Form8 />} />
      <Route path="/Exame-sindrome-metabólica" element={<Form19 />} />
      <Route path="/Exames-laboratoriais" element={<Form20 />} />
      <Route path="/Exames-bioquimicos-completos" element={<Form39 />} />
      <Route path="/Exames-bioquimicos-parcial" element={<Form40 />} />
      <Route path="/Hemograma" element={<Form41 />} />

      <Route path="/Resultados" element={<Form9 />} />
      <Route path="/Resultado-Policia-Militar" element={<Form151 />} />
      <Route path="/Resultado-Policia-Civil" element={<Form36 />} />
      <Route path="/Resultado-Corpo-De-Bombeiros" element={<Form152 />} />
      <Route path="/Resultado-Policia-Penal" element={<Form153 />} />
      <Route path="/Resultado-Guarda-Municipal" element={<Form154 />} />

      <Route path="/Escolher-Resultado-Militar" element={<Form150 />} />

      <Route path="/Paper" element={<Form10 />} />
      <Route path="/Escrever-Paper" element={<EscreverPaper />} />
      <Route path="/Ler-Paper" element={<LerPaper />} />

      <Route path="/Diagnostico-intervenção" element={<Form27 />} />
      <Route path="/Envio-relatorios" element={<Form28 />} />

      <Route path="/Escolher-Diagnostico" element={<Form30 />} />
      <Route path="/Diagnostico-Medico" element={<Form31 />} />
      <Route path="/Diagnostico-Nutricionista" element={<Form32 />} />
      <Route path="/Diagnostico-Psicologo" element={<Form33 />} />
      <Route path="/Diagnostico-Profissional-Ed-Fisica" element={<Form34 />} />
      <Route path="/Diagnostico-Geral-Saude" element={<Form11 />} />
      <Route
        path="/Diagnostico-Enfermagem"
        element={<FormDiagnosticoEnfermagem />}
      />
      <Route
        path="/Diagnostico-Fisioterapeuta"
        element={<FormDiagnosticoFisioterapeuta />}
      />
      <Route
        path="/Diagnostico-Cirurgiao-Dentista"
        element={<FormDiagnosticoCirurgiaoDentista />}
      />
      <Route
        path="/Diagnostico-Psiquiatra"
        element={<FormDiagnosticoPsiquiatra />}
      />
      <Route
        path="/Diagnostico-Cardiologista"
        element={<FormDiagnosticoCardiologista />}
      />
      <Route
        path="/Diagnostico-Ortopedista"
        element={<FormDiagnosticoOrtopedista />}
      />
      <Route
        path="/Diagnostico-Oftomologista"
        element={<FormDiagnosticoOftomologista />}
      />
      <Route
        path="/Diagnostico-Clinico-Geral"
        element={<FormDiagnosticoClinicoGeral />}
      />

      <Route path="/Resultado-Civil" element={<Form35 />} />
      <Route path="/Resultado-Escolar" element={<Form37 />} />
      <Route path="/Resultado-Sefaz" element={<Form38 />} />
      <Route path="/Sofrimento-Mental" element={<Form10000 />} />
      <Route path="/Inventario-Ansiedade-Beck" element={<Form100001 />} />
      <Route path="/Inventario-Depressao-Beck" element={<Form100002 />} />
      <Route path="/Questionario-Sofrimento-Mental" element={<Form100003 />} />


      <Route path="/Escolher-Tele-Consulta" element={<EscolherTeleConsulta />} />

      <Route path="/video-chamada" element={<VideoConferece />} />
      <Route path="/perfil" element={<FormProfile />} />
    </Routes>
  );
};

export default FormRouter;
