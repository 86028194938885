import React, { useState, useEffect } from "react";
import styled from "styled-components";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import { File } from "@phosphor-icons/react";

import HeaderGeral from "../../../Components/headerGeral";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  border: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  width: 100vw;
`;
const DivImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
`;

const Button = styled.button`
  background-color: blue;
  color: white;
  padding: 10px 80px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const ButtonReturn = styled.button`
  background-color: red;
  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

const FormContainer = styled.div`
  width: 90vw;
`;

const RightImage = styled.img`
  max-width: 18.23vw;
  height: auto;
  margin-left: 4vw;
  margin-bottom: 1vh;
`;

const PaperItem = styled.div`
  border: 1px solid #111;
  height: auto;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 5px;
`;

const Title = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 12px;
`;

const Authors = styled.p`
  font-size: 1rem;
  color: #111;
  margin-bottom: 8px;
`;

const Summary = styled.p`
  font-size: 1rem;
  margin-bottom: 8px;
  text-align: justify;
`;

const Keywords = styled.p`
  font-size: 1rem;
  color: #777;
  margin-bottom: 10px;
`;

const EscreverPaper = () => {
  const navigate = useNavigate();
  const [dados, setDados] = useState([]);

  const consultarDados = async () => {
    try {
      const { data, error } = await supabase.from("paper").select("*");

      if (error) {
      } else {
        setDados(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    consultarDados();
  }, []);

  const handleProfileClick = () => {
    navigate("/plataforma/Paper");
  };

  return (
    <Container>
      <HeaderGeral />

      <FormContainer>
        <ButtonReturn onClick={handleProfileClick}>&#8592; Voltar</ButtonReturn>
        {dados.map((item) => (
          <PaperItem key={item.id}>
            <Title>{item.title}</Title>
            <Authors>Autores: {item.authors}</Authors>
            <Summary>Resumo: {item.summary}</Summary>
            <Keywords>Palavras-chave: {item.keywords}</Keywords>
            <a
              href={decodeURIComponent(
                JSON.parse(item.file_url).data.publicUrl
              )}
              download
            >
              <Button>
                <File size={22} />
                Acessar Arquivo
              </Button>
            </a>
          </PaperItem>
        ))}
      </FormContainer>
    </Container>
  );
};

export default EscreverPaper;
