import React from "react";
import { useNavigate } from "react-router-dom";

import image40 from "../../../Images/image40.png";
import image41 from "../../../Images/image41.png";
import image42 from "../../../Images/image42.png";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import HeaderLaborais from "../../../Components/headerLaborais";

const images = [image40, image41, image42];
const cardTitles = [
  "EXAMES BIOQUÍMICOS COMPLETO",
  "EXAMES BIOQUÍMICOS PARCIAL",
  "HEMOGRAMA COMPLETO",
];

const Form20 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Exames-bioquimicos-completos`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Exames-bioquimicos-parcial`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Hemograma`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderLaborais />
      <MainContent>
        <WelcomeText>Exames laboratoriais</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form20;
