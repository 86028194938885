import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../Supabase/supabase";
import inlearning from "../../Images/inlearning.jpg";
import sias2 from "../../Images/bro.png";
import {
  TextAviso,
  LoginForm,
  FraseUm,
  CardsContainer,
  Card1,
  Card2,
  RightImage,
  InputField,
  SubmitButton,
  ContentCard1,
  Spam1,
  FraseDois,
  Spam2,
  Elipse,
  FraseTres,
  Label,
} from "./styles";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();

    setLoading(true);
    const { user, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      alert(error.error_description || error.message);
    } else {
      navigate("/plataforma/");
    }
    setLoading(false);
  };

  return (
    <CardsContainer>
      <Card1>
        <ContentCard1>
          <FraseUm>
            Somos um sistema de <Spam1>análise de dados</Spam1> voltados à Gestão em Saúde
          </FraseUm>

          <FraseDois>
            Baseado em técnicas de investigação científica, voltado a avaliação
            de informações na área da saúde, qualidade de vida e performance
            humana, bem como suas interfaces.
          </FraseDois>

          <FraseDois>
            Temos como objetivo principal extrair informações úteis a partir dos
            dados, possibilitando a
            <Spam2> tomada de decisões mais assertivas e orientadas.</Spam2>
          </FraseDois>

          <RightImage src={sias2} alt="sias2" />
        </ContentCard1>
        <Elipse />
      </Card1>

      <Card2>
        <RightImage src={inlearning} alt="inlearning" />
        <FraseTres>Acesse sua conta</FraseTres>
        <LoginForm onSubmit={handleLogin}>
          <Label>Email</Label>
          <InputField
            type="email"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
           <Label>Senha</Label>
          <InputField
            type="password"
            placeholder=" Digite sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton type="submit">Entrar</SubmitButton>
        </LoginForm>
        <TextAviso>
          Um produto desenvolvido pelo Instituto Educacional InLearning todos os
          direitos reservados. O SIAS obedece as regras da Lei nº 13.709 (Lei
          Geral de Proteção de Dados - LGPD), que estabelece um conjunto de
          regras para coleta, tratamento, armazenamento e compartilhamento de
          dados pessoais
        </TextAviso>
      </Card2>
    </CardsContainer>
  );
}

export default Login;
