import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  RightImage,
  FormContainer,
  FormGroup,
  FormHeader,
  FormLabel,
  StyledTextarea,
  FormInput,
  StyledInput,
  StyledButton,
  Button,
  CheckboxLabel,
  RadioLabel,
  DivImage,
} from "./styles";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form22 = () => {
  const [ingeriuMedicamentos, setIngeriuMedicamentos] = useState(null);
  const [todosPrescritos, setTodosPrescritos] = useState(null);
  const [recorreuAutomedicacao, setRecorreuAutomedicacao] = useState(null);
  const [motivosAutomedicacao, setMotivosAutomedicacao] = useState([]);
  const [duracaoTomaMedicamento, setDuracaoTomaMedicamento] = useState(null);
  const [procurouInformacoes, setProcurouInformacoes] = useState([]);
  const [localInformacoes, setLocalInformacoes] = useState([]);
  const [infoCompreendida, setInfoCompreendida] = useState(null);
  const [cumpriuInformacoes, setCumpriuInformacoes] = useState(null);
  const [medicamentosMedico, setMedicamentosMedico] = useState(null);
  const [quantosMedicamentos, setQuantosMedicamentos] = useState(null);
  const [verificouIncompatibilidades, setVerificouIncompatibilidades] =
    useState(null);
  const [pedeInfoFarmaceutico, setPedeInfoFarmaceutico] = useState(null);
  const [conhecimentoRiscos, setConhecimentoRiscos] = useState(null);
  const [problemaMedicacao, setProblemaMedicacao] = useState(null);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      ingeriuMedicamentos,
      todosPrescritos,
      recorreuAutomedicacao,
      motivosAutomedicacao,
      duracaoTomaMedicamento,
      procurouInformacoes,
      localInformacoes,
      infoCompreendida,
      cumpriuInformacoes,
      medicamentosMedico,
      quantosMedicamentos,
      verificouIncompatibilidades,
      pedeInfoFarmaceutico,
      conhecimentoRiscos,
      problemaMedicacao,
    };

    const { error } = await supabase.from("automedicação").insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Saude-e-qualidade-de-vida");
  };

  const FormCheckboxGroup = ({ label, options, state, setState }) => (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <div>
        {options.map((option, index) => (
          <CheckboxLabel key={index}>
            <input
              type="checkbox"
              value={option.value}
              checked={state.includes(option.value)}
              onChange={() => {
                if (state.includes(option.value)) {
                  setState((prev) =>
                    prev.filter((val) => val !== option.value)
                  );
                } else {
                  setState((prev) => [...prev, option.value]);
                }
              }}
            />
            {option.label}
          </CheckboxLabel>
        ))}
      </div>
    </FormGroup>
  );

  const FormRadioGroup = ({ label, options, state, setState }) => (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <div>
        {options.map((option, index) => (
          <RadioLabel key={index}>
            <input
              type="radio"
              value={option.value}
              checked={state === option.value}
              onChange={() => setState(option.value)}
            />
            {option.label}
          </RadioLabel>
        ))}
      </div>
    </FormGroup>
  );
  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>AUTOMEDICAÇÃO</h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Nº de CPF :</FormLabel>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="21.87vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Ingeriu medicamentos no último ano?</FormLabel>
            <label style={{ marginRight: "20px" }}>
              Sim
              <input
                type="radio"
                value="sim"
                checked={ingeriuMedicamentos === "sim"}
                onChange={() => setIngeriuMedicamentos("sim")}
              />
            </label>
            <label>
              Não
              <input
                type="radio"
                value="não"
                checked={ingeriuMedicamentos === "não"}
                onChange={() => setIngeriuMedicamentos("não")}
              />
            </label>
          </FormGroup>

          {ingeriuMedicamentos === "sim" && (
            <FormGroup>
              <FormLabel>
                Os medicamentos usados foram todos prescritos pelo médico?
              </FormLabel>
              <label style={{ marginRight: "20px" }}>
                Sim
                <input
                  type="radio"
                  value="sim"
                  checked={todosPrescritos === "sim"}
                  onChange={() => setTodosPrescritos("sim")}
                />
              </label>
              <label>
                Não
                <input
                  type="radio"
                  value="não"
                  checked={todosPrescritos === "não"}
                  onChange={() => setTodosPrescritos("não")}
                />
              </label>
            </FormGroup>
          )}

          {todosPrescritos === "não" && (
            <FormGroup>
              <FormRadioGroup
                label="No último ano recorreu à automedicação?"
                options={[
                  { label: "Raramente", value: "raramente" },
                  { label: "Alguma Frequência", value: "alguma" },
                  { label: "Muita Frequência", value: "muita" },
                ]}
                state={recorreuAutomedicacao}
                setState={setRecorreuAutomedicacao}
              />

              <FormGroup>
                <FormLabel>
                  Quais os problemas que apresentava quando recorreu à
                  automedicação?
                </FormLabel>
                <StyledTextarea />
              </FormGroup>

              <FormGroup>
                <FormLabel>
                  Quais os motivos que justificaram a automedicação em vez da
                  consulta médica?
                </FormLabel>
                <StyledTextarea />
              </FormGroup>

              <FormRadioGroup
                label="Quando se automedicou qual a duração da toma do medicamento?"
                options={[
                  { label: "1 a 2 Dias", value: "1-2" },
                  { label: "3 a 4 Dias", value: "3-4" },
                  { label: "5 dias ou mais", value: "5+" },
                ]}
                state={duracaoTomaMedicamento}
                setState={setDuracaoTomaMedicamento}
              />

              <FormCheckboxGroup
                label="A automedicação foi influenciada por:"
                options={[
                  { label: "Familiar ou Amigo", value: "familiar" },
                  {
                    label: "Profissional de saúde (não médico)",
                    value: "profissional",
                  },
                  { label: "Prescrições anteriores", value: "prescricoes" },
                  {
                    label: "Publicidade (TV, revistas, Internet)",
                    value: "publicidade",
                  },
                  {
                    label: "Tinha em casa sem prescrição médica",
                    value: "casa",
                  },
                ]}
                state={motivosAutomedicacao}
                setState={setMotivosAutomedicacao}
              />

              <FormRadioGroup
                label="Antes de se automedicar, procurou informações, ou esclarecimentos adicionais do medicamento?"
                options={[
                  { label: "Sim", value: "sim" },
                  { label: "Não", value: "não" },
                ]}
                state={procurouInformacoes}
                setState={setProcurouInformacoes}
              />

              {procurouInformacoes === "sim" && (
                <FormGroup>
                  <FormLabel>Onde procurou essas informações?</FormLabel>
                  <FormCheckboxGroup
                    label=""
                    options={[
                      {
                        label:
                          "Bula (informação que vem dentro da caixa do medicamento)",
                        value: "bula",
                      },
                      { label: "Enfermeiro", value: "enfermeiro" },
                      { label: "Farmacêutico", value: "farmaceutico" },
                      { label: "Parente ou Amigo", value: "parenteOuAmigo" },
                      { label: "Outro. Qual?", value: "outro" },
                    ]}
                    state={localInformacoes}
                    setState={setLocalInformacoes}
                  />

                  <FormRadioGroup
                    label="A informação recebida foi compreendida?"
                    options={[
                      { label: "Sim", value: "sim" },
                      { label: "Não", value: "não" },
                    ]}
                    state={infoCompreendida}
                    setState={setInfoCompreendida}
                  />

                  <FormRadioGroup
                    label="Cumpriu rigorosamente as informações recebidas?"
                    options={[
                      { label: "Sim", value: "sim" },
                      { label: "Não", value: "não" },
                    ]}
                    state={cumpriuInformacoes}
                    setState={setCumpriuInformacoes}
                  />
                </FormGroup>
              )}

              <FormRadioGroup
                label="Quando se automedicou encontrava-se a tomar medicamentos receitados pelo seu médico?"
                options={[
                  { label: "Sim", value: "sim" },
                  { label: "Não", value: "não" },
                ]}
                state={medicamentosMedico}
                setState={setMedicamentosMedico}
              />

              {medicamentosMedico === "sim" && (
                <FormGroup>
                  <FormLabel>
                    Quantos medicamentos se encontrava a tomar?
                  </FormLabel>
                  <StyledInput type="number" />
                </FormGroup>
              )}

              <FormRadioGroup
                label="Verificou se existiam algumas incompatibilidades entre os medicamentos que tomou?"
                options={[
                  { label: "Sim", value: "sim" },
                  { label: "Não", value: "não" },
                ]}
                state={verificouIncompatibilidades}
                setState={setVerificouIncompatibilidades}
              />

              <FormRadioGroup
                label="Quando compra medicamentos pede sempre informações ao farmacêutico acerca do medicamento?"
                options={[
                  { label: "Sim", value: "sim" },
                  { label: "Não", value: "não" },
                ]}
                state={pedeInfoFarmaceutico}
                setState={setPedeInfoFarmaceutico}
              />

              <FormRadioGroup
                label="Tem conhecimento dos riscos que o medicamento com que se automedicou poderiam causar?"
                options={[
                  { label: "Sim", value: "sim" },
                  { label: "Não", value: "não" },
                ]}
                state={conhecimentoRiscos}
                setState={setConhecimentoRiscos}
              />

              <FormRadioGroup
                label="Surgiu algum problema relacionado com a medicação com que se automedicou?"
                options={[
                  { label: "Sim", value: "sim" },
                  { label: "Não", value: "não" },
                ]}
                state={problemaMedicacao}
                setState={setProblemaMedicacao}
              />
            </FormGroup>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>

            <StyledButton>Enviar</StyledButton>
          </div>
        </form>
      </FormContainer>

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form22;
