import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 120vh;
  position: relative;
  width: 100%;
  background-image: radial-gradient(circle, #add8e6, #4169e1);

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export const WelcomeSection = styled.header`
  justify-content: flex-start;
  padding: 5px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const WelcomeText = styled.div`
  flex: 1;
  text-align: center;
  justify-content: flex-end;
  color: #f0c600;
  font-size: 1.9rem;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const WelcomeImage = styled.img`
  max-width: 350px;
  height: auto;
  margin-left: 180px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
  }
`;

export const MainContent = styled.main`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 12px;
    align-items: center;
    justify-content: center;
  }
`;

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 8px 8px 8px rgba(0, 0, 0.2, 0.4);
  cursor: pointer;
  text-align: center;
  color: #1f1087;
  text-align: center;
  width: 200px;
  height: 280px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    background-color: #ffffff;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  width: 220px;
  height: 215px;
`;

export const Button = styled.button`
  background-color: #1f1087;
  color: white;
  padding: 10px 40px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #1f1087;
    transform: scale(1.09);
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
