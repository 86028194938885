import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  FormRadio,
  FormHeader,
  FormInput,
  SubmitButton,
  Button,
} from "../../../Components/all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

const Form25 = () => {
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [q9, setQ9] = useState("");
  const [result, setResult] = useState("");

  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);
  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const calculateDepressionLevel = () => {
    const sum = [q1, q2, q3, q4, q5, q6, q7, q8, q9].reduce(
      (a, b) => a + Number(b),
      0
    );

    if (sum <= 4) setResult("sem depressão");
    else if (sum >= 5 && sum <= 9) setResult("transtorno depressivo leve");
    else if (sum >= 10 && sum <= 14)
      setResult("transtorno depressivo moderado");
    else if (sum >= 15 && sum <= 19)
      setResult("transtorno depressivo moderadamente grave");
    else if (sum >= 20 && sum <= 27) setResult("transtorno depressivo grave");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      result,
    };

    const { error } = await supabase.from("saudePacientePHQ9").insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Ansiedade-depressão");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>ESCALA DE DEPRESSÃO, ANSIEDADE E ESTRESSE</h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>
              Por favor, leia cuidadosamente cada uma das afirmações abaixo e
              circule o número apropriado 0,1,2 ou 3 que indique o quanto ela se
              aplicou a você durante a última semana, conforme a indicação a
              seguir:
              <br />
              <br />
              0 = Nunca
              <br />
              1 = Em vários dias
              <br />
              2 = Em mais da metade dos dias
              <br />
              3 = Em quase todos os dias
              <br />
            </FormLabel>
          </FormGroup>

          <FormGroup>
            <FormLabel>Nº de CPF :</FormLabel>
            <FormInput
              type="text"
              value={id}
              maxLength="14"
              onChange={handleCPFChange}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="25vw"
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tive pouco interesse ou prazer em fazer coisas
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q1"
                    value={value.toString()}
                    onChange={(e) => setQ1(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Senti desânimo, desalento ou falta de esperança
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q2"
                    value={value.toString()}
                    onChange={(e) => setQ2(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tive dificuldade em adormecer ou em dormir sem interrupções, ou
              dormi demais
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q3"
                    value={value.toString()}
                    onChange={(e) => setQ3(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Senti cansaço ou falta de energia </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q4"
                    value={value.toString()}
                    onChange={(e) => setQ4(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>Tive falta ou excesso de apetite</FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q5"
                    value={value.toString()}
                    onChange={(e) => setQ5(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Senti que não gosto de mim próprio/a — ou que sou um(a) falhado/a
              ou me desiludi a mim próprio/a ou à minha família
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q6"
                    value={value.toString()}
                    onChange={(e) => setQ6(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Tive dificuldade em concentrar-me nas coisas, como ao ler o jornal
              ou ver televisão
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q7"
                    value={value.toString()}
                    onChange={(e) => setQ7(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Movimentei-me ou falei tão lentamente que outras pessoaspoderão
              ter notado. Ou o oposto: estive agitado/a a ponto de andar de um
              lado para o outro muito mais do que é habitual
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q8"
                    value={value.toString()}
                    onChange={(e) => setQ8(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Pensei que seria melhor estar morto/a, ou em magoar-me a mim
              próprio/a de alguma forma
            </FormLabel>
            <FormRadioGroup>
              {[0, 1, 2, 3].map((value) => (
                <div key={value}>
                  <FormRadio
                    type="radio"
                    name="q9"
                    value={value.toString()}
                    onChange={(e) => setQ9(e.target.value)}
                  />
                  <FormLabel>{value}</FormLabel>
                </div>
              ))}
            </FormRadioGroup>
          </FormGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
              marginBottom: 30,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>
            <SubmitButton onClick={calculateDepressionLevel}>
              Mostrar Resultado
            </SubmitButton>
          </div>
          {result && <p>A gravidade do quadro é: {result}</p>}
        </form>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default Form25;
