import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  border: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  width: 100vw;
`;

export const DivImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
`;

export const RightImage = styled.img`
  max-width: 18.23vw;
  height: auto;
  margin-left: 4vw;
  margin-bottom: 1vh;
`;

export const FormContainer = styled.div`
  width: 90vw;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1rem;
`;

export const FormLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
`;

export const FormInput = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  background-color: #dcdcdc;
`;

export const SummaryInput = styled.textarea`
  font-size: 1rem;

  border-radius: 5px;
  border: 1px solid #ccc;
  width: ${({ width }) => width || "100%"};
  background-color: #dcdcdc;
  height: ${({ height }) => height || "auto"};
  text-align: justify;
  resize: vertical;
`;

export const StyledButton = styled.button`
  background-color: #4caf50;
  margin-bottom: 10px;
  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const StyledButton1 = styled.button`
  background-color: #ffa500;

  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const LoadingText = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  margin-top: 20px;
`;
export const DivInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const TextDiagnotico = styled.p`
  font-size: 30px;
  font-weight: bold;
  color: #111;
  margin-right: 10px;
  margin-top: 20px;
`;

export const StyledButton2 = styled.button`
  background-color: #0000ff;

  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const Button = styled.button`
  background-color: red;
  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  border: 1px solid #000;
`;

export const TableHeader = styled.th`
  background-color: #808080;
  padding: 10px;
  text-align: left;
  font-size: 1rem;
  text-align: justify;
`;

export const TableCell = styled.td`
  padding: 6px;
  border: 1px solid #000;
  font-size: 1rem;
  background-color: #fff;
`;
