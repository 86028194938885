import styled from "styled-components";

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  margin-bottom: 30px;
`;

export const InputField = styled.input`
  width: 25vw;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid #b8b8b8;
  transition: border-bottom 0.2s;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #888;
    font-style: italic;
  }
`;

export const SubmitButton = styled.button`
  width: 25vw;
  height: 40px;
  background-color: #1f1087;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
`;

export const Card1 = styled.div`
  background-color: #eeeef0;
  width: 50vw;
  height: 100vh;
`;

export const RightImage = styled.img`
  width: 19vw;
  height: 33vh;
  height: auto;
`;

export const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 50vw;
  height: 100vh;
  margin: 0;
`;

export const TextAviso = styled.p`
  font-size: 10px;
  text-align: justify;
  width: 25vw;
`;

export const FraseUm = styled.p`
  font-weight: 500;
  font-size: 32px;
  text-align: start;
  text-align: justify;
`;

export const Spam1 = styled.span`
  color: #1f1087;
  font-weight: 800;
`;

export const ContentCard1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 28vw;
  margin-left: 3vw;
  margin-top: 8vh;
  justify-content: flex-start;
`;

export const FraseDois = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: start;
  margin-top: 15px;
  text-align: justify;
`;

export const Spam2 = styled.span`
  color: #1f1087;
  font-weight: 700;
`;

export const Elipse = styled.div`
  background-color: #1f1087;
  width: 50vw;
  height: 25vh;
  position: relative;
  clip-path: polygon(0 40%, 100% 0, 100% 100%, 0 100%);
`;

export const FraseTres = styled.p`
  font-weight: 400;
  font-size: 26px;
  text-align: start;
  margin-top: 15px;
  text-align: justify;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
`;
