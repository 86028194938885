import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { User, SignOut } from "@phosphor-icons/react";
import sias3 from "../../Images/logo-IamLearning.png";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to left, #2e19bd, #1f1087);
  height: 10vh;
  position: relative;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  color: white;
`;

const WelcomeImage = styled.img`
  width: 7vw;
  height: 6.92vh;
  margin-left: 50px;
`;

const Button = styled.button`
  background-color: #f5a623;
  color: black;
  width: 10vw;
  height: 3.2vh;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f5a623;
    transform: scale(1.09);
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const ButtonLogout = styled.button`
  background-color: #f7f7f7;
  color: black;
  width: 10vw;
  height: 3.2vh;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f7f7f7;
    transform: scale(1.09);
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 50px;
  @media (max-width: 768px) {
    margin-left: 5.78vw;
  }
`;

const Header = () => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/plataforma/perfil");
  };

  const handleLogOutClick = () => {
    navigate("/login");
  };

  return (
    <HeaderContainer>
      <WelcomeImage src={sias3} alt="sias3" />

      <DivButtons>
        <Button onClick={handleProfileClick}>
          <User size={20} />
          Meu perfil
        </Button>
        <ButtonLogout onClick={handleLogOutClick}>
          <SignOut size={20} />
          Sair da conta
        </ButtonLogout>
      </DivButtons>
    </HeaderContainer>
  );
};

export default Header;
