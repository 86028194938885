import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  width: 100vw;
`;

export const Button = styled.button`
  background-color: #b8b8b8;
  color: black;
  border-radius: 4px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const DivButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 95vw;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const DivImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
`;

export const RightImage = styled.img`
  max-width: 18.23vw;
  height: auto;
  margin-left: 4vw;
  margin-bottom: 1vh;
`;

export const FormContainer = styled.div`
  width: 95vw;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const ChartContainer = styled.div`
  margin: 20px auto;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 45vw;
`;

export const ResultTitle = styled.h4`
  font-size: 1.4rem;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #1f1087;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  text-align: center;
`;

export const ToggleButton = styled.button`
  width: 100%;
  max-width: 95vw;
  background-color: #f4f4f5;
  margin: 15px auto;
  color: black;
  padding: 10px 15px;
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1f1087;
    color: white;
  }

  &:active {
    background-color: #1f1087;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;
