import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image17 from "../../../Images/image17.png";
import image18 from "../../../Images/image18.png";
import image19 from "../../../Images/image19.png";

import HeaderQuestionario from "../../../Components/headerQuestionarios";

const images = [image17, image18, image19];
const cardTitles = [
  "ÍNDICE DE MASSA CORPORAL - IMC",
  "ÍNDICE RELAÇÃO CINTURA/QUADRIL - IRCQ",
  "TESTE PERCENTUAL DE GORDURA",
];

const Form7 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/IMC`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/IRQC`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Teste-percentual-gordura`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderQuestionario />
      <MainContent>
        <WelcomeText>Avaliação antropométrica</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form7;
