import React, { useState } from "react";
import styled from "styled-components";
import supabase from "../../../Supabase/supabase";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import HeaderGeral from "../../../Components/headerGeral";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  border: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  width: 100vw;
`;

const DivImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
`;

const DivInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Button = styled.button`
  background-color: red;
  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

const FormContainer = styled.div`
  width: 90vw;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  font-size: 1.2rem;
`;

const FormLabel = styled.label`
  font-size: 1.2rem;
  margin-bottom: 10px;
  display: block;
`;

const FormInput = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  background-color: #dcdcdc;
`;

const RightImage = styled.img`
  max-width: 18.23vw;
  height: auto;
  margin-left: 4vw;
  margin-bottom: 1vh;
`;

const SummaryInput = styled.textarea`
  font-size: 1.2rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  text-align: justify;
  resize: vertical;
  background-color: #dcdcdc;
`;

const EscreverPaper = () => {
  const [title, setTitle] = useState("");
  const [authors, setAuthors] = useState("");
  const [summary, setSummary] = useState("");
  const [keywords, setKeywords] = useState("");
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (file) {
        const fileData = new FormData();
        fileData.append("file", file);

        const { data: fileUploadResponse, error: fileUploadError } =
          await supabase.storage
            .from("paper")
            .upload(`papers/${file.name}`, fileData, {
              cacheControl: "3600",
              upsert: false,
            });

        if (fileUploadError) {
          if (fileUploadError.response?.status === 400()) {
            toast.error("Esse arquivo ja foi inserido");
          } else {
            toast.error("Não foi possível inserir o arquivo.");
          }
          return;
        }

        const fileUrl = supabase.storage
          .from("paper")
          .getPublicUrl(`papers/${file.name}`);

        const { data: insertedData, error: insertError } = await supabase
          .from("paper")
          .upsert(
            [
              {
                title,
                authors,
                summary,
                keywords,
                file_url: fileUrl,
              },
            ],
            { returning: "full" }
          );

        if (insertError) {
          return;
        }

        toast.success("Dados inseridos com sucesso");
      } else {
        toast.error("Nenhum arquivo foi enviado");
      }
    } catch (error) {
      if (error.response?.status === 400) {
        toast.error("Esse arquivo ja foi inserido");
      } else {
        toast.error("Não foi possível inserir o arquivo.");
      }
      toast.error("Erro desconhecido");
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <DivInputs>
            <FormGroup>
              <FormLabel>Título</FormLabel>
              <FormInput
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                customWidth="32vw"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Autores</FormLabel>
              <FormInput
                type="text"
                value={authors}
                onChange={(e) => setAuthors(e.target.value)}
                customWidth="42vw"
              />
            </FormGroup>
          </DivInputs>

          <FormGroup>
            <FormLabel>Resumo</FormLabel>
            <SummaryInput
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              width="100%"
              height="27.04vh"
            />
          </FormGroup>
          <DivInputs>
            <FormGroup>
              <FormLabel>Palavras-chave</FormLabel>
              <FormInput
                type="text"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                customWidth="22vw"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Upload do Arquivo</FormLabel>
              <FormInput
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                customWidth="22vw"
              />
            </FormGroup>
          </DivInputs>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>Voltar</Button>

            <button
              type="submit"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                borderRadius: 5,
                border: "none",
                width: "9.12vw",
                height: "3.89vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span>Enviar</span>
            </button>
          </div>
        </form>
      </FormContainer>

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
};

export default EscreverPaper;
