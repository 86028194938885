import React from "react";
import styled from "styled-components";

import Header from "../../Components/header";

import ComponenteComPermissao from "../../Fuctions/Permissão";
import MenuFull from "../../Acessos/full";
import MenuAvaliador1 from "../../Acessos/avaliador1";
import MenuAvaliador2 from "../../Acessos/avaliador2";
import MenuAvaliador3 from "../../Acessos/avaliador3";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100vw;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

const Home = () => {
  return (
    <PageWrapper>
      <Header />
      <ComponenteComPermissao permissaoNecessaria="full">
        <MenuFull />
      </ComponenteComPermissao>
      <ComponenteComPermissao permissaoNecessaria="avaliador1">
        <MenuAvaliador1 />
      </ComponenteComPermissao>
      <ComponenteComPermissao permissaoNecessaria="avaliador2">
        <MenuAvaliador2 />
      </ComponenteComPermissao>
      <ComponenteComPermissao permissaoNecessaria="avaliador3">
        <MenuAvaliador3 />
      </ComponenteComPermissao>
    </PageWrapper>
  );
};

export default Home;
