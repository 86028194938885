import React, { useState } from "react";
import supabase from "../../../Supabase/supabase";
import HeaderGeral from "../../../Components/headerGeral";
import { useNavigate } from "react-router-dom";
import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormInput,
  FormSelect,
  FormCheckboxGroup,
  FormHeader,
  FormCheckboxLabel,
  FormCheckbox,
  Button,
  DivInputs,
  ButtonSend,
} from "../../../Components/all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";
import { CaretLeft } from "@phosphor-icons/react";

function Form29() {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [identification, setIdentification] = useState("");
  const [gender, setGender] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [numberOfChildren, setNumberOfChildren] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [corporationType, setCorporationType] = useState("");
  const [hierarchy, setHierarchy] = useState("");
  const [rank, setRank] = useState("");
  const [role, setRole] = useState("");
  const [shift, setShift] = useState("");
  const [race, setRace] = useState("");
  const [yearsInCorporation, setYearsInCorporation] = useState("");
  const [workHours, setWorkHours] = useState("");
  const [workDays, setWorkDays] = useState("");
  const [otherPaidActivity, setOtherPaidActivity] = useState("");
  const [numberOfOtherPaidActivities, setNumberOfOtherPaidActivities] =
    useState("");
  const [absencesLastYear, setAbsencesLastYear] = useState("");
  const [, setSubmitted] = useState(false);
  const [afastamentoCausas, setAfastamentoCausas] = useState([]);
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [email, setEmail] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailValid(isValidEmail(newEmail));
  };

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, ""); // Mantém apenas números
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleAfastamentoCausasChange = (event) => {
    const selectedCausas = [...afastamentoCausas];
    const causa = event.target.value;

    if (event.target.checked) {
      selectedCausas.push(causa);
    } else {
      const index = selectedCausas.indexOf(causa);
      if (index !== -1) {
        selectedCausas.splice(index, 1);
      }
    }

    setAfastamentoCausas(selectedCausas);
  };

  const validateFormData = () => {
    if (!name.trim()) {
      return "Por favor, insira um nome válido.";
    }
    if (!id.trim()) {
      return "Por favor, insira um CPF válido.";
    }
    if (!gender) {
      return "Por favor, selecione um sexo.";
    }
    if (!ageRange) {
      return "Por favor, selecione uma faixa etária.";
    }
    if (!race) {
      return "Por favor, selecione uma raça/cor.";
    }
    if (!maritalStatus) {
      return "Por favor, selecione um estado civil.";
    }
    if (!numberOfChildren) {
      return "Por favor, selecione o número de filhos.";
    }

    if (!educationLevel) {
      return "Por favor, selecione a escolaridade.";
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateFormData();
    if (validationError) {
      alert(validationError);
      return;
    }

    const formData = {
      name,
      id,
      gender,
      ageRange,
      race,
      maritalStatus,
      numberOfChildren,
      educationLevel,
      corporationType,
      workHours,
      hierarchy,
      rank,
      role,
      shift,
      yearsInCorporation,
      workDays,
      otherPaidActivity,
      numberOfOtherPaidActivities,
      absencesLastYear,
      email,
      afastamentoCausas,
    };

    const { error } = await supabase
      .from("sociodemograficoSefaz")
      .insert([formData]);

    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      setSubmitted(true);
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  if (
    name &&
    identification &&
    id &&
    gender &&
    ageRange &&
    race &&
    maritalStatus &&
    numberOfChildren &&
    educationLevel &&
    corporationType &&
    workHours &&
    hierarchy &&
    rank &&
    role &&
    shift &&
    yearsInCorporation &&
    workDays &&
    otherPaidActivity &&
    numberOfOtherPaidActivities &&
    absencesLastYear
  ) {
  }

  const handleAbsencesChange = (e) => {
    const selectedValue = e.target.value;
    setAbsencesLastYear(selectedValue);

    if (selectedValue !== "Nenhuma vez") {
      setShowAdditionalForm(true);
    } else {
      setShowAdditionalForm(false);
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Sociodemografico");
  };

  return (
    <Container>
      <HeaderGeral />
      <FormContainer>
        <FormHeader>
          <h2>QUESTIONÁRIO SOCIODEMOGRÁFICO &#8594; SEFAZ</h2>
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <DivInputs>
            <FormGroup>
              <FormLabel>Nome:</FormLabel>
              <FormInput
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                customWidth="15.87vw"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Nº de identificação (Registro da Corporação):
              </FormLabel>
              <FormInput
                type="text"
                value={identification}
                onChange={(e) => setIdentification(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormGroup>
              <FormLabel>Seu Email :</FormLabel>
              <FormInput
                type="text"
                value={email}
                onChange={handleEmailChange}
                customWidth="15.87vw"
              />
              {!isEmailValid && (
                <div style={{ color: "red" }}>
                  Por favor, insira um email válido.
                </div>
              )}
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>Sexo:</FormLabel>
              <FormSelect
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Feminino">Feminino</option>
                <option value="Masculino">Masculino</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Raça/Cor:</FormLabel>
              <FormSelect
                value={race}
                onChange={(e) => setRace(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Preto">Preto</option>
                <option value="Pardo">Pardo</option>
                <option value="Branco">Branco</option>
                <option value="Indígina">Indígina</option>
                <option value="Amarelo">Amarelo</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Faixa etária:</FormLabel>
              <FormSelect
                value={ageRange}
                onChange={(e) => setAgeRange(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="20-30">20 - 30</option>
                <option value="31-40">31 - 40</option>
                <option value="41-50">41 – 50</option>
                <option value="acima-50">Acima de 50 anos</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Estado Civil:</FormLabel>
              <FormSelect
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Solteiro">Solteiro</option>
                <option value="Casado">Casado</option>
                <option value="União Estável">União Estável</option>
                <option value="Divorciado">Divorciado</option>
                <option value="Viúvo">Viúvo</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>Número de filhos:</FormLabel>
              <FormSelect
                value={numberOfChildren}
                onChange={(e) => setNumberOfChildren(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Nenhum">Nenhum</option>
                <option value="01 Filho">01 Filho</option>
                <option value="De 2 a 3 filhos">De 2 a 3 filhos</option>
                <option value="De 3 a 4 filhos">De 3 a 4 filhos</option>
                <option value="Mais de 4 filhos">Mais de 4 filhos</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Escolaridade:</FormLabel>
              <FormSelect
                value={educationLevel}
                onChange={(e) => setEducationLevel(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Ensino Médio">Ensino Médio</option>
                <option value="Ensino Superior Incompleto">
                  Ensino Superior Incompleto
                </option>
                <option value="Ensino Superior Completo">
                  Ensino Superior Completo
                </option>
                <option value="Pós-Graduação">Pós-Graduação</option>
                <option value="Mestrado">Mestrado</option>
                <option value="Doutorado">Doutorado</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>Cargo Ocupado:</FormLabel>
              <FormSelect
                value={rank}
                onChange={(e) => setRank(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Auditor Fiscal">Auditor Fiscal</option>
                <option value="Agente Fiscal de Tributos">
                  Agente Fiscal de Tributos
                </option>
                <option value="Analista do Tesouro Estadual">
                  Analista do Tesouro Estadual
                </option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Função:</FormLabel>
              <FormSelect
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Administrativo">Administrativo</option>
                <option value="Operacional">Operacional</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Turno de Trabalho:</FormLabel>
              <FormSelect
                value={shift}
                onChange={(e) => setShift(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Manhã e Tarde">Manhã e Tarde</option>
                <option value="Tarde e Noite">Tarde e Noite</option>
                <option value="Escalas">Escalas</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>Tempo de corporação (em anos):</FormLabel>
              <FormSelect
                value={yearsInCorporation}
                onChange={(e) => setYearsInCorporation(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="0-5">0 a 5 anos</option>
                <option value="6-10">6 a 10 anos</option>
                <option value="11-15">11 a 15 anos</option>
                <option value="16-20">16 a 20 anos</option>
                <option value="21-25">21 a 25 anos</option>
                <option value="26+">Mais de 25 anos</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Carga Horária de Trabalho Semanal (em horas):
              </FormLabel>
              <FormSelect
                value={workHours}
                onChange={(e) => setWorkHours(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="20">20 horas</option>
                <option value="30">30 horas</option>
                <option value="40">40 horas</option>
                <option value="acima-40">Acima de 40 horas</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Período de trabalho por semana</FormLabel>
              <FormSelect
                value={workDays}
                onChange={(e) => setWorkDays(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="De segunda à sexta">De segunda à sexta</option>
                <option value="12 horas de trabalho por 36 horas de folga">
                  12 horas de trabalho por 36 horas de folga
                </option>
                <option value="15 dias de trabalho por 15 dias de folga">
                  15 dias de trabalho por 15 dias de folga
                </option>
                <option value="10 dias de trabalho por 20 dias de folga">
                  10 dias de trabalho por 20 dias de folga
                </option>
              </FormSelect>
            </FormGroup>
          </DivInputs>
          <DivInputs>
            <FormGroup>
              <FormLabel>
                Outra atividade remunerada fora da Corporação?
              </FormLabel>
              <FormSelect
                value={otherPaidActivity}
                onChange={(e) => setOtherPaidActivity(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Quantidade de atividades remuneradas fora da Corporação?
              </FormLabel>
              <FormSelect
                value={numberOfOtherPaidActivities}
                onChange={(e) => setNumberOfOtherPaidActivities(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3-mais">De 3 a mais serviços</option>
              </FormSelect>
            </FormGroup>
          </DivInputs>

          <FormGroup>
            <FormLabel>
              Quantas vezes se afastou do trabalho (último ano):
            </FormLabel>
            <FormSelect
              value={absencesLastYear}
              onChange={handleAbsencesChange}
              customWidth="20.87vw"
            >
              <option value="Nenhuma vez">Nenhuma vez</option>
              <option value="1-2 vezes">De 1 a 2 vezes</option>
              <option value="3-4 vezes">De 3 a 4 vezes</option>
              <option value="4-5 vezes">De 4 a 5 vezes</option>
              <option value="mais-5 vezes">Mais de 5 vezes</option>
            </FormSelect>
          </FormGroup>

          {showAdditionalForm && (
            <FormGroup>
              <FormCheckboxGroup>
                <FormLabel>
                  Se houve afastamento do trabalho no último ano, qual a causa?
                  (marque mais de uma opção se necessário)
                </FormLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças Infecciosas, parasitárias e virais"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças Infecciosas, parasitárias e virais"
                    )}
                  />
                  Doenças Infecciosas, parasitárias e virais
                </FormCheckboxLabel>

                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Neoplasia,doenças no sangue e endócrinas"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Neoplasia,doenças no sangue e endócrinas"
                    )}
                  />
                  Neoplasia (câncer), doenças no sangue e endócrinas (hormônios)
                </FormCheckboxLabel>

                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Transtornos mentais, psicológicos e comportamentais"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Transtornos mentais, psicológicos e comportamentais"
                    )}
                  />
                  Transtornos mentais, psicológicos e comportamentais, Ex:
                  depressão, ansiedade ou outras.
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças do sistema Nervoso"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças do sistema Nervoso"
                    )}
                  />
                  Doenças do sistema Nervoso
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças nos olhos e/ou ouvido"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças nos olhos e/ou ouvido"
                    )}
                  />
                  Doenças nos olhos e/ou ouvido, Ex: dores no ouvido,
                  conjuntivite ou outras.
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças do aparelho circulatório"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças do aparelho circulatório"
                    )}
                  />
                  Doenças do aparelho circulatório, Ex: Hipertensão Arterial ou
                  outras.
                </FormCheckboxLabel>

                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças do aparelho respiratório"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças do aparelho respiratório"
                    )}
                  />
                  Doenças do aparelho respiratório, Ex: Asma ou outras.
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças da cavidade oral"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças da cavidade oral"
                    )}
                  />
                  Doenças da cavidade oral (boca), Ex: dor de dente ou outras.
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças do aparelho digestório"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças do aparelho digestório"
                    )}
                  />
                  Doenças do aparelho digestório, Ex: dores no estômago ou
                  outras.
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças de pele"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes("Doenças de pele")}
                  />
                  Doenças de pele, Ex: micoses ou outras.
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças do aparelho osteomioarticular"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças do aparelho osteomioarticular"
                    )}
                  />
                  Doenças do aparelho osteomioarticular (ossos, músculos,
                  articulações), Ex: lesões musculares, torções, fraturas ou
                  outras.
                </FormCheckboxLabel>

                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Doenças do aparelho genital e urinário"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Doenças do aparelho genital e urinário"
                    )}
                  />
                  Doenças do aparelho genital e urinário, Ex: infecção urinária
                  ou outras.
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Gravidez, parto ou puerpério "
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Gravidez, parto ou puerpério "
                    )}
                  />
                  Gravidez, parto ou puerpério
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Lesões, envenenamento e causas externas"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Lesões, envenenamento e causas externas"
                    )}
                  />
                  Lesões, envenenamento e causas externas (Trauma, queimadura)
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Consultas ou exames médicos"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes(
                      "Consultas ou exames médicos"
                    )}
                  />
                  Consultas ou exames médicos
                </FormCheckboxLabel>
                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Acidentes em serviço"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes("Acidentes em serviço")}
                  />
                  Acidentes em serviço
                </FormCheckboxLabel>

                <FormCheckboxLabel>
                  <FormCheckbox
                    type="checkbox"
                    value="Outros"
                    onChange={handleAfastamentoCausasChange}
                    checked={afastamentoCausas.includes("Outros")}
                  />
                  Outros: <input type="text" />
                </FormCheckboxLabel>
              </FormCheckboxGroup>
            </FormGroup>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginRight: 20,
            }}
          >
            <Button onClick={handleProfileClick}>
              <CaretLeft size={14} />
              Voltar
            </Button>

            <ButtonSend type="submit">
              <span>Enviar</span>
            </ButtonSend>
          </div>
        </form>
      </FormContainer>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Container>
  );
}

export default Form29;
